import {Column} from 'components/TableCoreOld/columns/index';
import {createIconPart} from './combinedColumn/combinedColumnsUtils';
import {iconLibByIcon} from 'utilsOld/icons';
import {isDefined} from 'services/SecondaryMethods/typeUtils';

export const getIconColor = (rowData, color, colorField) =>
  colorField && isDefined(rowData[colorField]) ? rowData[colorField] : color;

export default class IconColumn extends Column {
  prop() {
    const {alignment} = super.prop();

    return {
      ...super.prop(),
      allowHeaderFiltering: false,
      cellTemplate: (cellElement, {value, row}) => {
        const {parsedDisplayCustomParam} = this.field;
        const {Color: color, IconSize: size, ColorField} = parsedDisplayCustomParam;
        const {iconLibrary = '', iconClass = ''} = iconLibByIcon(value);
        const rowIconBlock = createIconPart(iconClass, size, iconLibrary);
        rowIconBlock.style.color = getIconColor(row.data, color, ColorField);
        if (!alignment) {
          rowIconBlock.style.display = 'flex';
          rowIconBlock.style.alignItems = 'center';
          rowIconBlock.style.justifyContent = 'center';
        }

        cellElement.appendChild(rowIconBlock);
      }
    };
  }
}
