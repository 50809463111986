import {isObject} from 'services/SecondaryMethods/typeUtils';
import CustomStore from 'devextreme/data/custom_store';
import {DEFAULT_LOOKUP_ROW_PER_PAGE, getSelectFieldData} from 'utilsOld/createFieldDataSource';
import {Model} from 'services/requests/model';
import onInitLookupFieldShared from 'utilsOld/onInitLookupFieldShared';
import {valueToArray} from 'utilsOld/valueCasters';
import {fields, system} from 'services/objects';
import {FormField} from 'utilsOld/systemObjects';
import {D5Error} from 'services/SecondaryMethods/errors';
import {SysFormFields} from 'services/interfaces/sysObjects';
import {COLUMN_WIDTH} from './columnSizes';
import {isTagStyleField} from '../../../utilsOld/sysFormUtils';
import {SortByDefault} from '../../../middlewares/userScript/elems/public-interfaces';

const {
  ENUM_DS: {VALUE_EXPR, DISPLAY_EXPR},
  DISPLAY_TYPE: {TEXT, LINK, ENUMERATOR, LOOKUP, ICON}
} = system;

interface ILookupProps {
  calculateDisplayValue: string;
  fieldName: string;
  dictKeyFld?: string;
  dictObj?: string;
  dictDisplayFld?: string;
  sortValues?: string[];
  storageKey: string,
}

export const getLookupProps = ({
  calculateDisplayValue,
  fieldName,
  dictKeyFld,
  dictObj,
  dictDisplayFld,
  storageKey,
  sortValues
}: ILookupProps) => ({
  isAnyOf: true,
  calculateDisplayValue,
  setCellValue: (newData: any, nexValue: any, _currentRowData: any, text: any) => {
    if (isObject(nexValue) && 'displayValue' in nexValue && 'value' in nexValue) {
      newData[fieldName] = nexValue.value;
      newData[calculateDisplayValue] = nexValue.displayValue;
    } else {
      newData[fieldName] = nexValue;
      newData[calculateDisplayValue] = text;
    }
  },
  lookup: {
    allowClearing: true,
    dataSource: () => {
      return {
        pageSize: DEFAULT_LOOKUP_ROW_PER_PAGE,
        paginate: true,
        store: new CustomStore({
          key: dictKeyFld,
          load: loadOptions => {
            const params = onInitLookupFieldShared.getParams(storageKey);
            return getSelectFieldData({
              formID: '',
              formKey: '',
              name: '',
              loadOptions,
              dictObj,
              dictKeyFld,
              operationsParams: params,
              dictNameFld: dictDisplayFld,
              dictDisplayFld,
              sortValues
            })
          },
          byKey: key => {
            if (Array.isArray(key) && !key.length) {
              return Promise.resolve();
            }

            return new Promise(resolve => {
              const ObjectModel = new Model(dictObj);

              ObjectModel.fetch({
                data: {
                  Columns: [dictKeyFld, dictDisplayFld],
                  Filters: {
                    // @ts-ignore
                    [dictKeyFld]: {'=': key}
                  },
                  Sorts: [dictDisplayFld]
                },
                success: function(res: {toJSON: () => any}) {
                  resolve(res.toJSON());
                }
              });
            });
          }
        })
      };
    },
    displayExpr: dictDisplayFld,
    valueExpr: dictKeyFld
  }
});

interface IEnumProps {
  fieldName: string;
  storageKey: string;
  isAnyOf: boolean;
}

export const getEnumProps = ({fieldName, storageKey, isAnyOf}: IEnumProps) => ({
  isAnyOf: isAnyOf,
  calculateDisplayValue: (rowData: {[x: string]: any}) => {
    const ds = onInitLookupFieldShared.getDataSource(storageKey) || [];
    const values = valueToArray(rowData[fieldName]);
    return values.map(value => ds.find((item: {[x: string]: any}) => item[VALUE_EXPR] === value)?.[DISPLAY_EXPR]);
  },
  lookup: {
    allowClearing: true,
    dataSource: () => {
      return onInitLookupFieldShared.getDataSource(storageKey) || [];
    },
    displayExpr: DISPLAY_EXPR,
    valueExpr: VALUE_EXPR
  }
});

export const checkDisplayTypeForTreeText = (field?: SysFormFields) => {
  if (!field) return undefined;

  const displayType = new FormField(field)?.displayType;
  // @ts-ignore
  if ([TEXT, LINK, ENUMERATOR, LOOKUP].includes(displayType)) {
    return field;
  }
  D5Error.log('E3001', [getKeyByValue(displayType), fields.TreeTextField]);
  return undefined;
};

export const checkDisplayTypeForTreeIcon = (field?: SysFormFields) => {
  if (!field) return undefined;

  const displayType = new FormField(field)?.displayType;
  // @ts-ignore
  if ([ICON].includes(displayType)) {
    return field;
  }
  D5Error.log('E3001', [getKeyByValue(displayType), fields.TreeIconField]);
  return undefined;
};

export const getKeyByValue = (value: number) => {
  const {DISPLAY_TYPE} = system;
  // @ts-ignore
  return Object.keys(DISPLAY_TYPE).find((key: any) => DISPLAY_TYPE[key] === value);
};

export const getMaxTagsCount = (maxTagsCount: number) => {
  switch (maxTagsCount) {
    case 0:
    case -1:
    case null:
      return undefined;
    default:
      return maxTagsCount;
  }
};

export const getConvertedHtmlToText = (value: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = value;
  const decodedString = tempDiv.textContent;
  tempDiv.remove();

  return decodedString?.replace(/<[^>]*>/g, '');
};

export const columnWidthByType = (type: number) => {
  const {
    FIELD_TYPE: {NUMBER, BOOL, TEXT, DATE}
  } = system;

  const allowedTypes: number[] = [NUMBER, BOOL, TEXT, DATE];
  // @ts-ignore
  return allowedTypes.includes(type) ? COLUMN_WIDTH[type].DEFAULT : COLUMN_WIDTH.OTHER_TYPES.DEFAULT;
};

interface TagStyleProps {
  tagColorSchemeFieldName?: string,
  tagStylingModeFieldName?: string,
  columnName: string,
  data: Record<string, any>
}

export const getStylingArrays = ({
  tagColorSchemeFieldName,
  tagStylingModeFieldName,
  columnName,
  data
}: TagStyleProps) => {
  let tagColorSchemeArr: number[] = [];
  let tagStylingModeArr: number[] = [];
  Object.keys(data).forEach(key => {
    if (isTagStyleField(columnName, tagColorSchemeFieldName ?? '', key)) {
      tagColorSchemeArr = data[key];
    }
    if (isTagStyleField(columnName, tagStylingModeFieldName ?? '', key)) {
      tagStylingModeArr = data[key];
    }
  });

  return {tagColorSchemeArr, tagStylingModeArr};
};

export const getSortOrder = ({
  sortByDefault,
  isFixedOrder,
  fixedOrderField,
  isFixedOrderField = false,
}: {
  sortByDefault?: SortByDefault,
  isFixedOrder?: boolean,
  fixedOrderField?: string,
  isFixedOrderField?: boolean
}) => {
  if (isFixedOrder && isFixedOrderField) {
    return 'asc';
  }
  if (isFixedOrder && fixedOrderField) {
    return undefined;
  }
  if (sortByDefault === SortByDefault.ASC) {
    return 'asc';
  }
  if (sortByDefault === SortByDefault.DESC) {
    return 'desc';
  }
  return undefined;
};
