import {createAction} from '@reduxjs/toolkit';
import {createAsyncThunk} from '../reduxUtils';
import {Dispatch} from 'redux';
import {
  IButtonOptions,
  ILayoutItem,
  IReportList,
  LayoutButtons
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {promisedThrottleAC} from 'middlewares/throttleAction';
import {BUTTON_TYPE, ExpandOperation} from 'services/interfaces/global-interfaces';
import {makeColumnsSelector} from 'services/tables/selectors';
import {DisableButtonsPayload, TablesItemState} from 'services/tables/interfaces';
import {GridColumn} from 'components/TableCoreOld/column-interfaces';
import {IFilterTemplate} from '../filterTemplates/interfaces';
import {excludeTemplateButtons, pushReportListButtons, pushTemplateButtons} from './utils';
import {mergeColumnOptions} from './mergeColumnOptions';
import {applyStoredSettingsToToolbarButtons, ITbButtonsPosition} from 'components/TableCoreOld/ColumnSettings/utils';
import {DocPanelStorageFilter} from 'components/FilterPanel/FilterSettings/types';
import {RootState} from 'store';
import {SysForm} from '../interfaces/sysObjects';
import {makeDataSourceSelector, makeOperationsParamsSelector} from '../currentForms/selectors';
import {SysFormWrapper} from 'utilsOld/systemObjects';
import {getRequestColumns} from 'utilsOld/getRequestColumns';
import {postWithPromise} from '../requests/baseRequests';
import {wapiUrl} from '../baseUrl';

export enum TableActionTypes {
  CLEAR_TABLE_ROW_DATA = 'tables/CLEAR_TABLE_ROW_DATA',
  SET_TOOLBAR_BUTTONS = 'tables/SET_TOOLBAR_BUTTONS',
  SET_COLUMNS = 'tables/SET_COLUMNS',
  SET_REPORT_LIST = 'tables/REPORT_LIST',
  DISABLE_BUTTONS = 'tables/DISABLE_BUTTONS',
  HIDE_BUTTONS = 'tables/HIDE_BUTTONS',
  TO_DEFAULT = 'tables/TO_DEFAULT',
  SWITCH_BUTTONS_PRESSED = 'tables/SWITCH_BUTTONS_PRESSED',
  TOGGLE_BUTTON_OPTION = 'tables/TOGGLE_BUTTON_OPTION',
  SET_DEFAULT_PAGE_SIZE = 'tables/SET_DEFAULT_PAGE_SIZE',
  UPDATE_COLUMNS_BY_COLUMNSETTINGS = 'tables/UPDATE_COLUMNS_BY_COLUMNSETTINGS',
  RESET_PAGE_SIZE = 'tables/RESET_PAGE_SIZE',
  SET_PAGE_SIZE = 'tables/SET_PAGE_SIZE',
  SET_PAGES_PREDICTED = 'tables/SET_PAGES_PREDICTED',
  DESTROY = 'tables/DESTROY',
  SET_SUMMARY_VALUES = 'tables/SET_SUMMARY_VALUES',
  UPDATE_TABLES = 'tables/UPDATE_TABLES',
  SET_EXPANDED_STATE = 'tables/SET_EXPANDED_STATE',
  CLEAR_EXPANDED_STATE = 'tables/CLEAR_EXPANDED_STATE',
  ACTIVATE_GOTO = 'tables/ACTIVATE_GOTO',

  CLEAR_ROWS_TO_UPDATE = 'tables/CLEAR_ROWS_TO_UPDATE',
  SET_HEADER_CONTEXT_MENU_BUTTONS = 'tables/SET_HEADER_CONTEXT_MENU_BUTTONS',
  INIT_DOC_FILTER = 'tables/INIT_DOC_FILTER',
  TOGGLE_TOTAL_ROW = 'tables/TOGGLE_TOTAL_ROW',
  INIT_TABLE_STATE = 'tables/INIT_TABLE_STATE'
}

export const setDocFilter = createAction(
  TableActionTypes.INIT_DOC_FILTER,
  (formKey: string, filter: DocPanelStorageFilter[]) => ({
    payload: {
      formKey,
      filter
    }
  })
);

export const toggleTotalRow = createAsyncThunk<{formKey: string, visible: boolean}, {formKey: string, visible?: boolean}>(
  TableActionTypes.TOGGLE_TOTAL_ROW,
  async (arg, thunkAPI) => {
    const currentVisible= thunkAPI.getState().tables[arg.formKey]?.isShowTotalRow;
    return {
      formKey: arg.formKey,
      visible: arg.visible ?? !currentVisible
    }
  }
);

type InitTableStateAction = {
  columns: TablesItemState['columns']
  buttons: TablesItemState['buttons']
  isShowTotalRow: TablesItemState['isShowTotalRow']
  defaultTotalRowKind: TablesItemState['defaultTotalRowKind']
  formKey: string
}
export const initTableState = createAction<InitTableStateAction>(TableActionTypes.INIT_TABLE_STATE)

export const clearRowsToUpdate = createAction(TableActionTypes.CLEAR_ROWS_TO_UPDATE, (formKey: string) => ({
  payload: {
    formKey
  }
}));

export const clearExpandedState = createAction(TableActionTypes.CLEAR_EXPANDED_STATE, (formKey: string) => ({
  payload: {
    formKey
  }
}));

export const setExpandedState = createAction(
  TableActionTypes.SET_EXPANDED_STATE,
  (formKey: string, operation: ExpandOperation) => ({
    payload: {
      formKey,
      operation
    }
  })
);

export const setColumns = createAction(TableActionTypes.SET_COLUMNS, (formKey: string, columns: GridColumn[]) => ({
  payload: {
    formKey,
    columns
  }
}));

export const setReportList = createAction(
  TableActionTypes.SET_REPORT_LIST,
  (formKey: string, reportList: IReportList[]) => ({
    payload: {
      formKey,
      reportList
    }
  })
);

export const updateColumnsByVisibleColumns =
  (formKey: string, cols: GridColumn[], propsArr: Array<keyof GridColumn> = []) =>
  (dispatch: Dispatch, getState: any) => {
    const storedCols: GridColumn[] = makeColumnsSelector()(getState(), formKey) || [];
    let mergedCols = mergeColumnOptions(storedCols, cols, propsArr);

    if (mergedCols !== storedCols) {
      dispatch(setColumns(formKey, mergedCols));
    }
  };
export const updateColumnsBySortIndexColumns =
  (dataSource: Array<GridColumn> = [], formKey: string) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    let sortIndex = 0; //
    const storedCols: GridColumn[] = makeColumnsSelector()(getState(), formKey) || [];
    const updatedColsMap = new Map();

    for (const item of dataSource) {
      updatedColsMap.set(item.caption, {sortOrder: item.sortOrder});

      if (item.sortOrder) {
        updatedColsMap.get(item.caption).sortIndex = sortIndex;
        sortIndex += 1;
      }
    }

    const mergedCols = storedCols.map(col => {
      const updatedCol = updatedColsMap.get(col.caption);

      if (updatedCol) {
        return {
          ...col,
          ...updatedCol,
          sortIndex: updatedCol.sortOrder ? updatedCol.sortIndex : undefined
        };
      }

      return col;
    });

    return dispatch(setColumns(formKey, mergedCols));
  };

export const updateColumnsByColumnSettingsTab = createAction(
  TableActionTypes.UPDATE_COLUMNS_BY_COLUMNSETTINGS,
  (formKey: string, newState: Array<{caption: string; visible: boolean; isVisibleOnEditDockPanel: boolean}>) => ({
    payload: {
      formKey,
      newState
    }
  })
);

export const setDefaultPageSize = createAction(
  TableActionTypes.SET_DEFAULT_PAGE_SIZE,
  (formKey: string, defaultPageSize: number) => ({
    payload: {
      formKey,
      defaultPageSize
    }
  })
);

export const resetPageSize = createAction(TableActionTypes.RESET_PAGE_SIZE, (formKey: string) => ({
  payload: {
    formKey
  }
}));

export const setPageSize = createAction(TableActionTypes.SET_PAGE_SIZE, (formKey: string, pageSize: number) => ({
  payload: {
    formKey,
    pageSize
  }
}));
export const setPagesPredicted = createAction(
  TableActionTypes.SET_PAGES_PREDICTED,
  (formKey: string, pagesPredicted: number) => ({
    payload: {
      formKey,
      pagesPredicted
    }
  })
);

export const setSummaryValues = createAction(
  TableActionTypes.SET_SUMMARY_VALUES,
  (formKey: string, summaryValues: Record<string, any>) => ({
    payload: {
      formKey,
      summaryValues
    }
  })
);

export const setButtons = createAction(
  TableActionTypes.SET_TOOLBAR_BUTTONS,
  (formKey: string, buttons: ILayoutItem<IButtonOptions>[]) => ({
    payload: {
      formKey,
      buttons
    }
  })
);

export const updateTables = createAction(
  TableActionTypes.UPDATE_TABLES,
  (tables: (Partial<TablesItemState> & {formKey: string})[]) => ({
    payload: [...tables]
  })
);

export const disableButtons = createAction(
  TableActionTypes.DISABLE_BUTTONS,
  (formKey: string, newEnabled: BUTTON_TYPE[], newDisabled: BUTTON_TYPE[]) => ({
    meta: {
      payloadAccumulator(prevPayload: DisableButtonsPayload): DisableButtonsPayload {
        function difference(setA: any[], setB: any[]) {
          let _difference = new Set(setA);
          for (let elem of setB) {
            _difference.delete(elem);
          }
          return _difference;
        }

        const excludedEnabled = difference(prevPayload.enabled, newDisabled);
        const excludedDisabled = difference(prevPayload.disabled, newEnabled);

        return {
          ...prevPayload,
          // @ts-ignore
          enabled: [...new Set([...excludedEnabled, ...newEnabled])],
          // @ts-ignore
          disabled: [...new Set([...excludedDisabled, ...newDisabled])]
        };
      },
      key: `${formKey}/${TableActionTypes.DISABLE_BUTTONS}`,
      throttleTimeout: 60
    },
    payload: {
      formKey,
      enabled: newEnabled,
      disabled: newDisabled
    }
  })
);

// @ts-ignore
export const disableButtonsPromised = promisedThrottleAC(disableButtons);

export const toggleButtonOption = createAction(
  TableActionTypes.TOGGLE_BUTTON_OPTION,
  (formKey: string, buttonType: number | string, option: keyof IButtonOptions) => ({
    payload: {
      formKey,
      buttonType,
      option
    }
  })
);

export const hideButtons = createAction(TableActionTypes.HIDE_BUTTONS, (formKey: string, buttons: BUTTON_TYPE[]) => ({
  payload: {
    formKey,
    buttons
  }
}));

export const toolbarToDefaultState = createAction<{
  formKey: string;
  buttons: ILayoutItem<IButtonOptions>[];
}>(TableActionTypes.TO_DEFAULT);

export const switchButtonsPressed = createAction(
  TableActionTypes.SWITCH_BUTTONS_PRESSED,
  (formKey: string, buttons: BUTTON_TYPE[], value: boolean) => ({
    payload: {
      formKey,
      buttons,
      value
    }
  })
);

export const activateGoToButton = createAction<{formKey: string; selectedData: Record<string, any>[]}>(
  TableActionTypes.ACTIVATE_GOTO
);

export const clearTableRowData = createAction<string>(TableActionTypes.CLEAR_TABLE_ROW_DATA);

export const destroyTable = createAction<string>(TableActionTypes.DESTROY);

export const updateToolbarButtons =
  (formKey: string, filterTemplates: IFilterTemplate[], reportList: IReportList[], lsButtons: ITbButtonsPosition[]) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.tables[formKey]) {
      return;
    }
    const buttons = state.tables[formKey]?.buttons as LayoutButtons;

    const updatedToolbarButtons = [
      excludeTemplateButtons,
      (buttons: LayoutButtons) => pushTemplateButtons(buttons, filterTemplates),
      (buttons: LayoutButtons) => pushReportListButtons(buttons, reportList),
      (storeButtons: LayoutButtons) => applyStoredSettingsToToolbarButtons({lsButtons, storeButtons})
    ].reduce((res, modifier: (b: LayoutButtons) => LayoutButtons) => modifier(res), buttons);

    dispatch(setButtons(formKey, updatedToolbarButtons));
  };

export const setHeaderContextMenuButtons = createAction(
  TableActionTypes.SET_HEADER_CONTEXT_MENU_BUTTONS,
  (formKey: string, buttons: ILayoutItem<IButtonOptions>[]) => ({
    payload: {
      formKey,
      buttons
    }
  })
);

export const tableRowUpdateByIds = createAsyncThunk<
  {formID: string; formKey: string; formData: Record<string, any>[]} | void,
  {ids: number[]; parentFormKey: string; actionFormId: string}
>('tables/tableRowUpdateByIds', async ({actionFormId, ids, parentFormKey}, {getState}) => {
  const sysForm = getState().requests.Sys_Forms.find((form: SysForm) => form.ID === actionFormId);
  if (!sysForm)
    //если полноэкранная форма редактирования загружена по ссылке
    return;

  const params = makeOperationsParamsSelector()(getState(), actionFormId);
  const dataSource = makeDataSourceSelector()(getState(), actionFormId);

  if (dataSource?.disabled) {
    return;
  }

  const wrapper = new SysFormWrapper(sysForm);

  const data = {
    Columns: getRequestColumns(wrapper.formFields, wrapper.keyField),
    Filters: {[wrapper.keyField]: ids},
    Page: -1,
    Params: params
  };

  return postWithPromise({data, url: `${wapiUrl}/${wrapper.object}/List`}).then(({error, response}) => {
    if (error) {
      throw error;
    }

    return {
      formID: actionFormId,
      formKey: parentFormKey,
      formData: response[wrapper.object] || []
    };
  });
});
