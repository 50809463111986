export const actionTypes = {
  SIGN_UP_SUCCESS: 'registration/SIGN_UP_SUCCESS',
  SIGN_IN_SUCCESS: 'login/SIGN_IN_SUCCESS',
  SIGN_OUT_SUCCESS: 'session/SIGN_OUT_SUCCESS',
};

export const signInSuccess = data => ({
  type: actionTypes.SIGN_IN_SUCCESS,
  payload: data,
});

export const signOutSuccess = () => ({
  type: actionTypes.SIGN_OUT_SUCCESS,
});
