import {EditableRowDataState} from 'middlewares/userScript/storeStates/editableRowDataState';
import {D5Error} from 'services/SecondaryMethods/errors';
import {GridColumn} from 'components/TableCoreOld/column-interfaces';
import {validateEnumDataSource} from 'middlewares/userScript/utils';
import {getSysDisplayField} from 'utilsOld/sysFormUtils';
import onInitLookupFieldShared, {createStorageKey} from 'utilsOld/onInitLookupFieldShared';
import DynamicLayoutFieldItemCreator from '../../../../services/tables/DynamicItemCreators/DynamicFieldItemCreator';
import {SummaryDataState} from '../../storeStates/SummaryDataState';
import {isNumber} from 'lodash';
import {customizeTextFunc} from '../../../../components/TableCoreOld/TotalRow/summary_row';
import {SysFormFields} from '../../../../services/interfaces/sysObjects';
import {getOriginalSummaryValue} from '../../../../components/TableCoreOld/TotalRow/utils';
import {SortOrder} from 'devextreme/common';
import {isEmptyValue} from '../../../../services/SecondaryMethods/typeUtils';
import {Locale} from '../../../../services/interfaces/global-interfaces';
import {FormField} from '../../../../utilsOld/systemObjects';

interface D5ColumnParams {
  column: GridColumn;
  editableRowData: EditableRowDataState | null;
  onModifyOption: () => void;
  summaryData: SummaryDataState;
  regSettings: Locale;
  formFields: Array<SysFormFields>;
}

interface ID5Column {
  order: number;
  isRequired: boolean;
  isVisible: boolean;
  isCustomizable: boolean;
  isReadOnly: boolean;
  readonly name: string;
  title: string;
  readonly isBaseMultiField: boolean | undefined;
  readonly isDynamicColumn: boolean | undefined;
  readonly groupID: number | null;
  fixedToLeft: boolean;
  lineCount: number;
  datasource: any;
  filter: any;
  value: any;
}

export class D5Column implements ID5Column {
  private col: GridColumn;
  private onModifyOption: () => void;
  private editableRowData: EditableRowDataState | null;
  private _onInitLookupFieldKey: string;
  protected readonly fieldType: number;
  private summaryData: SummaryDataState;
  private formFields: Array<SysFormFields>;
  private readonly regSettings: Locale;

  constructor({column, editableRowData, onModifyOption, summaryData, regSettings, formFields}: D5ColumnParams) {
    this.col = column;
    this.onModifyOption = onModifyOption;
    this.editableRowData = editableRowData;
    this._onInitLookupFieldKey = createStorageKey(this.col.userData?.formID, this.name);
    this.fieldType = column.userData && column.userData.fieldType;
    this.summaryData = summaryData;
    this.regSettings = regSettings;
    this.formFields = formFields;
  }

  get columns() {
    return this.col?.columns;
  }

  get order() {
    return this.col.visibleIndex;
  }

  set order(order: number) {
    if (order === this.col.visibleIndex) return;

    this.col.visibleIndex = order;
    this.onModifyOption();
  }

  set isRequired(isRequired: boolean) {
    if (isRequired === this.col.required) return;

    this.col.required = isRequired;
    this.onModifyOption();
  }

  get isRequired() {
    return this.col.required;
  }

  get isVisible() {
    return this.col.visible;
  }

  set summaryValue(nextValue: number) {
    if (!isNumber(nextValue)) {
      D5Error.log('E2022');
      return;
    }

    let name = this.col.name;
    if (this.isDynamicColumn) {
      name = DynamicLayoutFieldItemCreator.getBaseFieldName(this.col.dataField)!;
    }
    const field = this.formFields.find(field => field.Name === name);
    if (field) {
      const column = new FormField(field);
      const format = column.viewFormat;
      const customizeNextValue = customizeTextFunc({
        value: nextValue,
        format,
        column,
        regSetting: this.regSettings
      });
      this.summaryData?.rewriteSource({
        ...this.summaryData.get(),
        [this.col.dataField]: customizeNextValue
      });
    }
  }

  get summaryValue() {
    const summary = this.summaryData.get();
    const value = getOriginalSummaryValue(summary[this.col.dataField]);
    if (isNumber(value)) {
      return value;
    }
    return 0;
  }

  set isVisible(isVisible: boolean) {
    if (isVisible === this.col.visible) return;

    this.col.visible = isVisible;
    this.onModifyOption();
  }

  get isVisibleOnEditDockPanel() {
    return this.col.isVisibleOnEditDockPanel;
  }

  set isVisibleOnEditDockPanel(isVisibleOnEditDockPanel: boolean) {
    if (isVisibleOnEditDockPanel === this.col.isVisibleOnEditDockPanel) return;

    this.col.isVisibleOnEditDockPanel = isVisibleOnEditDockPanel;
    this.onModifyOption();
  }

  get isCustomizable() {
    return this.col.isCustomizable;
  }

  get isHiddenByUser() {
    return !!this.col.userData?.wasHiddenByUser;
  }

  set isCustomizable(isCustomizable: boolean) {
    if (isCustomizable === this.col.isCustomizable) return;

    this.col.isCustomizable = isCustomizable;
    this.onModifyOption();
  }

  get isReadOnly() {
    return this.col.isReadOnly;
  }

  set isReadOnly(isReadOnly: boolean) {
    if (isReadOnly === this.col.isReadOnly) return;

    this.col.isReadOnly = isReadOnly;
    this.onModifyOption();
  }

  // todo id property
  // get id() {
  //   return this._id;
  // }

  get name() {
    if (this.isDynamicColumn) {
      const creator = new DynamicLayoutFieldItemCreator({
        baseField: DynamicLayoutFieldItemCreator.getBaseFieldName(this.col.dataField)!
      });
      return creator.dataName(this.col.dataField);
    }
    return this.col.dataField;
  }

  get title() {
    return this.col.caption;
  }

  get isBaseMultiField() {
    return this.col.userData?.baseMultiField;
  }

  get isDynamicColumn() {
    return !!this.col.userData?.isDynamicColumn;
  }

  get dynamicColumns() {
    return [];
  }

  get isSystemColumn() {
    return this.col.userData?.isSystemColumn;
  }

  get ID() {
    return this.col.ID;
  }

  get groupID() {
    return this.col.groupID;
  }

  set title(text: string) {
    if (text === this.col.caption) return;

    this.col.caption = text;
    this.onModifyOption();
  }

  get fixedToLeft(): boolean {
    return !!(this.col.fixed && this.col.fixedPosition === 'left');
  }

  set fixedToLeft(value: boolean) {
    if (value === this.col.fixed) return;

    if (!isEmptyValue(this.col.groupParentID)) {
      throw D5Error.create('E2023');
    }

    this.col.fixed = value;
    this.col.fixedPosition = value ? 'left' : undefined;
    this.onModifyOption();
  }

  get lineCount(): number {
    return this.col.userData.maxLineCount;
  }

  set lineCount(value: number) {
    if (value === this.col.userData.maxLineCount) return;

    this.col.userData.maxLineCount = value;
    this.onModifyOption();
  }

  get sortOrder(): SortOrder | undefined {
    return this.col.sortOrder;
  }

  set sortOrder(value: SortOrder | undefined) {
    if (value === this.col.sortOrder) return;

    this.col.sortOrder = value;
    this.onModifyOption();
  }

  get sortIndex(): number | undefined {
    return this.col.sortIndex;
  }

  set sortIndex(value: number | undefined) {
    if (value === this.col.sortIndex) return;

    this.col.sortIndex = value;
    this.onModifyOption();
  }

  get datasource() {
    return onInitLookupFieldShared.getDataSource(this._onInitLookupFieldKey) || [];
  }

  set datasource(arr: Array<any>) {
    const fieldType = this.col.userData!.fieldType;
    const isInvalid = validateEnumDataSource(arr, fieldType);

    if (isInvalid) {
      D5Error.log('E1015', [this.name]);
    }
    onInitLookupFieldShared.setDataSource(this._onInitLookupFieldKey, arr);
    this.onModifyOption();
  }

  get filter() {
    return onInitLookupFieldShared.getFilters(this._onInitLookupFieldKey);
  }

  set filter(filter: Record<string, any>) {
    onInitLookupFieldShared.setFilters(this._onInitLookupFieldKey, filter);
    this.onModifyOption();
  }

  get operationsParams() {
    return onInitLookupFieldShared.getParams(this._onInitLookupFieldKey);
  }

  set operationsParams(params: Record<string, any>) {
    onInitLookupFieldShared.setParams(this._onInitLookupFieldKey, params);
    this.onModifyOption();
  }

  /**
   * При инлайн/батч редактировании метод возвращает значение поля (dataField)
   * редактируемой записи
   */
  get value(): any {
    if (!this.editableRowData) {
      D5Error.log('E1010', [this.col.dataField]);
      return undefined;
    }

    return this.editableRowData.get()[this.col.dataField];
  }

  /**
   * При инлайн/батч редактировании метод устанавливает значение поля (dataField)
   * для редактируемой записи
   */
  set value(nextValue) {
    if (!this.editableRowData) {
      D5Error.log('E1011', [this.col.dataField]);
      return;
    }

    this.editableRowData.rewriteSource({
      ...this.editableRowData.get(),
      [this.name]: nextValue
    });
  }

  set displayValue(nextValue: any) {
    this.editableRowData?.rewriteSource({
      ...this.editableRowData.get(),
      [getSysDisplayField(this.name)]: nextValue
    });
  }
}
