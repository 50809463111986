export interface AvailableStyles {
  background: string,
  backgroundColor: string,
  fontSize: string,
  lineHeight: string,
  color: string,
  fontWeight: string,
}

const availableStyles: AvailableStyles = {
  background: '',
  backgroundColor: '',
  fontSize: '',
  lineHeight: '',
  color: '',
  fontWeight: '',
};

export const stylesWrapper = <T extends {style: Record<string, any>}>(el: T) => {
  return new Proxy(availableStyles, {
    set(target: AvailableStyles, p: string, value: any, receiver: any): boolean {
      if (!availableStyles.hasOwnProperty(p)) {
        return true;
      }
      el.style[p as any] = value;
      return Reflect.set(target, p, value, receiver);
    },
  });
};
