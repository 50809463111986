import {D5BaseField} from 'middlewares/userScript/elems/D5BaseField';
import {system} from 'services/objects';
import {ItemsState} from 'middlewares/userScript/storeStates/itemsState';
import LayoutType from 'utilsOld/systemObjects/LayoutType';
import {getLookupDisplayField} from 'utilsOld/sysFormUtils';
import {IFieldOptions, ILayoutItem} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {D5Error} from 'services/SecondaryMethods/errors';
import {isArray} from 'services/SecondaryMethods/typeUtils';
import {validateSelectField} from '../utils';

const {
  DISPLAY_TYPE: {ENUMERATOR, LOOKUP}
} = system;

declare interface D5FormFieldOptions {
  fieldName: string;
  id: number;
  formItems: ItemsState;
  formData: Record<string, any>;
  modifyForm?: () => void;
}

export class D5FormField extends D5BaseField {
  private readonly formData: Record<string, any>;
  private readonly _lookupDisplayField: string;
  protected _objectField: string;
  private readonly _dxType: string;
  protected readonly fieldType: number;
  protected modifyForm: (() => void) | undefined;
  private readonly formItems: ItemsState;

  constructor({fieldName, formData, formItems, id, modifyForm}: D5FormFieldOptions) {
    super({
      formItems,
      name: fieldName,
      id
    });
    this.index = this.collection.findIndex(({name, itemType}) => fieldName === name && LayoutType.isField(itemType));

    if (this.index === -1) {
      throw new Error(`Field ${fieldName} is not found`);
    }
    const item: ILayoutItem<IFieldOptions> = this.collection.item(this.index);

    this.formData = formData;
    this._objectField = item.options.objectName;
    this._lookupDisplayField = getLookupDisplayField(this._objectField, item.options.displayExpr!);
    this._dxType = item.options.dxType;
    this.fieldType = item.options.fieldType;
    this.modifyForm = modifyForm;
    this.formItems = formItems;
  }

  private get isFile() {
    return this.fieldType === system.FIELD_TYPE.FILE && this.getOption('isFile');
  }

  private get isMultiSelect() {
    return this.getOption('isMultiSelect');
  }

  private get isValueMustBeArray() {
    return this.getOption('isAnyOf') || this.getOption('isMultiSelect');
  }

  get value() {
    let val = this.getOption('value');

    if (this._dxType === system.DX_CONTROLS.dxFileUploader && Array.isArray(val) && val[0]) {
      let [file] = val;
      return file instanceof File ? {FileType: file.type, FileName: file.name, FileSize: file.size} : file;
    }

    //у тагбоксів повертає пустий масив, а в скприатах очікують null
    if (this.isValueMustBeArray && !val?.length) {
      return null
    }

    return val;
  }

  set value(value: any) {
    let tempValue: any = value;
    if (!this.formData) {
      D5Error.log('E2020', [this._objectField]);
      return;
    }

    if (this.isFile && value && !isArray(value) && (value instanceof File)) {
      tempValue = [value];
    }

    if ([ENUMERATOR, LOOKUP].includes(this.displayType)){
      validateSelectField(this.isMultiSelect, tempValue, this.name)
    }

    //в скриптах можуть передати null, а тагбокс очікує []
    if (this.isValueMustBeArray && !tempValue) {
      tempValue = [];
    }

    this.formData[this._objectField] = tempValue;
    this.setOption('value', tempValue);
    this.setOption('displayValue', undefined);
    if (typeof this.modifyForm === 'function') {
      this.modifyForm();
    }
  }

  get parentField(): D5FormField | undefined {
    const parenFieldID = this.getOption('parentFieldID')

    if (!parenFieldID) return undefined;

    const parentField = this.collection.find(item => item.id === parenFieldID);

    return parentField ? new D5FormField({
      formData: this.formData,
      id: parentField.id,
      fieldName: parentField.name,
      formItems: this.formItems,
      modifyForm: this.modifyForm
    }) : undefined;
  }

  get displayValue() {
    return this.formData[this._lookupDisplayField];
  }

  set displayValue(value: string) {
    this.formData[this._lookupDisplayField] = value;
    this.setOption('displayValue', value);
  }

  get lineCount() {
    return this.getOption('lineCount');
  }

  set lineCount(lineCount: number) {
    this.setOption('lineCount', lineCount);
  }

  //export diagram
  public exportAsSVG(): Promise<string | null> {
    const fn = this.getOption('exportAsSVG');
    if (!fn) return Promise.resolve(null);
    return fn();
  }

  /**
   * По замовчуванню value повертає {FileType, FileName, FileSize}
   * exportAsFile повертає дані у вигляді блобу
   */
  public exportAsFile() {
    let val = this.getOption('value');
    if (!this.isFile) {
      throw D5Error.create('E2024');
    }
    if (isArray(val) && val[0]) {
      return val[0];
    }
    return val;
  }
}
