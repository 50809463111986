export const objects = {
  SysAppObjects: 'Sys_AppObjects',
  SysFormFilterFields: 'Sys_FormFilterFields',
  SysAppObjectOperations: 'Sys_AppObjectOperations',
  SysSubsystems: 'Sys_Subsystems',
  SysForms: 'Sys_Forms',
  SysFormFields: 'Sys_FormFields',
  Sys_FormFilterTemplates: 'Sys_FormFilterTemplates',
  Sys_UserFavoriteFilters: 'Sys_UserFavoriteFilters',
  Sys_FormQuickTips: 'Sys_FormQuickTips',
  SysFormGroups: 'Sys_FormGroups',
  Sys_FormSchedulerDayOff: 'Sys_FormSchedulerDayOff',
  Sys_FormSchedulerResources: 'Sys_FormSchedulerResources',
  Sys_FormSchedulerViews: 'Sys_FormSchedulerViews',
  SysUsers: 'Sys_Users',
  SysRoles: 'Sys_Roles',
  SysRoleOperationAccess: 'Sys_RoleOperationAccess',
  SysRoleFieldsRestriction: 'Sys_RoleFieldsRestriction',
  SysRoleSubsystemAccess: 'Sys_RoleSubsystemAccess',
  SysPositions: 'Sys_Positions',
  SysPositionRoles: 'Sys_PositionRoles',
  SysPositionAccess: 'Sys_PositionAccess',
  SysUserPositions: 'Sys_UserPositions',
  SysUserPositionAdditionAccess: 'Sys_UserPositionAdditionAccess',
  SysFormButtons: 'Sys_FormButtons',
  SysFormFieldEvents: 'Sys_FormFieldEvents',
  SysSubForms: 'Sys_SubForms',
  SysAppObjectFilters: 'Sys_AppObjectFilters',
  SysFormButtonEvents: 'Sys_FormButtonEvents',
  SysFormFilterFieldEvents: 'Sys_FormFilterFieldEvents',
  SysFormDecorationElements: 'Sys_FormDecorationElements',
  SysFormButtonFillingRules: 'Sys_FormButtonFillingRules',
  SysReportList: 'Sys_ReportList',
  SysApplications: 'Sys_Applications'
} as const;

export const fields = {
  _id: '_id',
  ID: 'ID',
  UserID: 'UserID',
  PersonID: 'PersonID',
  ObjectID: 'ObjectID',
  ObjectID_Name: 'ObjectID.Name',
  Application: 'ApplicationID.Name',
  ObjectID_KeyObjectFieldID_Name: 'ObjectID.KeyObjectFieldID.Name',
  ObjectID_KeyObjectFieldID_FieldType: 'ObjectID.KeyObjectFieldID.FieldType',
  Toolbar_View_Type: 'ToolbarViewType',

  FilterID: 'FilterID',
  FilterName: 'FilterName',
  SubsystemName: 'SubsystemName',
  SubsystemTitlePath: 'SubsystemTitlePath',

  Order: 'Order',
  IsDynamicField: 'IsDynamicField',
  Translations: 'Translations',
  Columns: 'Columns',
  Name: 'Name',
  Width: 'Width',
  ButtonWidth: 'ButtonWidth',
  GroupWidth: 'GroupWidth',
  Cols: 'Cols',
  Rows: 'Rows',
  IsResizable: 'IsResizable',
  LocationOnGrid: 'LocationOnGrid',
  ParentSubsystemID: 'ParentSubsystemID',
  ParentID: 'ParentID',
  ParentSubsystem_Name: 'ParentSubsystemID.Name',
  ParentSubsystem_Title: 'ParentSubsystemID.Title',
  ParentSubsystemID_ParentSubsystemID_Title: 'ParentSubsystemID.ParentSubsystemID.Title',
  ParentSubsystemID_ParentSubsystemID_ID: 'ParentSubsystemID.ParentSubsystemID.ID',
  MarkerSubsystems: 'markerSubsystems',
  FormID: 'FormID',
  FormID_Type: 'FormID.Type',
  ActionFormName: 'ActionFormName',
  DialogActionFormName: 'DialogActionFormName',
  FormName: 'FormName',
  FormID_Name: 'FormID.Name',
  FormID_Title: 'FormID.Title',
  DefaultFilter: 'DefaultFilter',
  AutoRefreshTimeout: 'AutoRefreshTimeout',
  IsAutoRefresh: 'IsAutoRefresh',
  IsFormFilterTemplate: 'IsFormFilterTemplate',
  Guid: 'Guid',
  UIVersion: 'UIVersion',
  LevelByName: 'LevelByName',
  LevelByID: 'LevelByID',
  FormID_ViewMode: 'FormID.ViewMode',
  Form_Open_Mode: 'FormOpenMode',
  FormID_ObjectID: 'FormID.ObjectID',
  FormID_ObjectID_Name: 'FormID.ObjectID.Name',
  FormID_ObjectID_KeyObjectFieldID_Name: 'FormID.ObjectID.KeyObjectFieldID.Name',
  IsGroup: 'IsGroup',
  IsRefreshOnDemand: 'IsRefreshOnDemand',
  IsFilterOnShow: 'IsFilterOnShow',
  IsFixedColumnWidth: 'IsFixedColumnWidth',
  IsFixedColumnOrder: 'IsFixedColumnOrder',
  IsSortingAllowed: 'IsSortingAllowed',
  IsDisabled: 'IsDisabled',
  IsFixedOrder: 'IsFixedOrder',
  IsFixedLeft: 'IsFixedLeft',
  IsHasChildren: 'IsHasChildren',
  IsShowColumnCustomization: 'IsShowColumnCustomization',
  IsShowButtonCustomization: 'IsShowButtonCustomization',
  IsTreeDragDropAllowed: 'IsTreeDragDropAllowed',
  IsUseAlternativeRowColor: 'IsUseAlternativeRowColor',
  IsShowColumnHeader: 'IsShowColumnHeader',
  IsShowColumnLines: 'IsShowColumnLines',
  IsShowRowLines: 'IsShowRowLines',
  IsFormBodyScrollable: 'IsFormBodyScrollable',
  IsShowBorder: 'IsShowBorder',
  IsCustomConditionList: 'IsCustomConditionList',
  IsVisibleOnEditDockPanel: 'IsVisibleOnEditDockPanel',
  CustomConditionList: 'CustomConditionList',
  DefaultTreeExpandKind: 'DefaultTreeExpandKind',
  DefaultTreeExpandLevel: 'DefaultTreeExpandLevel',
  DefaultModalSize: 'DefaultModalSize',
  FixedOrderField: 'FixedOrderField.Name',
  LookupObjectName: 'LookupObjectName',
  LookupKeyFieldName: 'LookupKeyFieldName',
  LookupDisplayFieldName: 'LookupDisplayFieldName',
  LookupSearchKind: 'LookupSearchKind',
  DefaultGroupFieldID: 'DefaultGroupFieldID',
  DefaultGroupFieldID_Name: 'DefaultGroupFieldID.Name',
  DefaultGroupFieldID_LinkedObjectID_Name: 'DefaultGroupFieldID.LinkedObjectID.Name',
  DefaultGroupFieldID_LinkedObjectID_DisplayObjectFieldID_Name:
    'DefaultGroupFieldID.LinkedObjectID.DisplayObjectFieldID.Name',
  DefaultGroupFieldID_LinkedObjectID_KeyObjectFieldID_Name: 'DefaultGroupFieldID.LinkedObjectID.KeyObjectFieldID.Name',
  SchedulerPropertiesID_DescriptionFieldID_Name: 'SchedulerPropertiesID.DescriptionFieldID.Name',
  SchedulerPropertiesID_DisabledFieldID_Name: 'SchedulerPropertiesID.DisabledFieldID.Name',
  SchedulerPropertiesID_EndDateFieldID_Name: 'SchedulerPropertiesID.EndDateFieldID.Name',
  SchedulerPropertiesID_EndDayHour: 'SchedulerPropertiesID.EndDayHour',
  SchedulerPropertiesID_FirstDayOfWeek: 'SchedulerPropertiesID.FirstDayOfWeek',
  SchedulerPropertiesID_IsAllowAppointmentSharing: 'SchedulerPropertiesID.IsAllowAppointmentSharing',
  SchedulerPropertiesID_IsAllowDrag: 'SchedulerPropertiesID.IsAllowDrag',
  SchedulerPropertiesID_IsAllowResizeAppointment: 'SchedulerPropertiesID.IsAllowResizeAppointment',
  SchedulerPropertiesID_IsShowNavigatorToolbar: 'SchedulerPropertiesID.IsShowNavigatorToolbar',
  SchedulerPropertiesID_IsReadOnly: 'SchedulerPropertiesID.IsReadOnly',
  SchedulerPropertiesID_StartDateFieldID_Name: 'SchedulerPropertiesID.StartDateFieldID.Name',
  SchedulerPropertiesID_StartDayHour: 'SchedulerPropertiesID.StartDayHour',
  SchedulerPropertiesID_TextFieldID_Name: 'SchedulerPropertiesID.TextFieldID.Name',
  SchedulerPropertiesID_IsShowTooltip: 'SchedulerPropertiesID.IsShowTooltip',

  TileViewProperties_BaseItemHeight: 'TileViewPropertiesID.BaseItemHeight',
  TileViewProperties_BaseItemWidth: 'TileViewPropertiesID.BaseItemWidth',
  TileViewProperties_DefaultGroupFieldDisplayName: 'TileViewPropertiesID.DefaultGroupFieldDisplayName',
  TileViewProperties_DefaultGroupFieldKeyField: 'TileViewPropertiesID.DefaultGroupFieldKeyField',
  TileViewProperties_DefaultGroupFieldName: 'TileViewPropertiesID.DefaultGroupFieldName',
  TileViewProperties_DefaultGroupFieldObject: 'TileViewPropertiesID.DefaultGroupFieldObject',
  TileViewProperties_Direction: 'TileViewPropertiesID.Direction',
  TileViewProperties_GroupStylingMode: 'TileViewPropertiesID.GroupStylingMode',
  TileViewProperties_GroupsPerPage: 'TileViewPropertiesID.GroupsPerPage',
  TileViewProperties_HeightRatioFieldName: 'TileViewPropertiesID.HeightRatioFieldName',
  TileViewProperties_IsUseGroupButton: 'TileViewPropertiesID.IsUseGroupButton',
  TileViewProperties_ItemMargin: 'TileViewPropertiesID.ItemMargin',
  TileViewProperties_ShowScrollbarMode: 'TileViewPropertiesID.ShowScrollbarMode',
  TileViewProperties_TilesPerPage: 'TileViewPropertiesID.TilesPerPage',
  TileViewProperties_RowsPerGroup: 'TileViewPropertiesID.RowsPerGroup',
  TileViewProperties_WidthRatioFieldName: 'TileViewPropertiesID.WidthRatioFieldName',

  ListViewProperties_IsMultiselect: 'ListViewPropertiesID.IsMultiselect',
  ListViewProperties_IsSelectionEnabled: 'ListViewPropertiesID.IsSelectionEnabled',
  ListViewProperties_ItemContentPadding: 'ListViewPropertiesID.ItemContentPadding',
  ListViewProperties_ItemHeight: 'ListViewPropertiesID.ItemHeight',
  ListViewProperties_ItemMargin: 'ListViewPropertiesID.ItemMargin',
  ListViewProperties_ItemMenuMode: 'ListViewPropertiesID.ItemMenuMode',
  ListViewProperties_MaxRowCount: 'ListViewPropertiesID.MaxRowCount',
  ListViewProperties_ShowScrollbarMode: 'ListViewPropertiesID.ShowScrollbarMode',

  ObjectFieldID: 'ObjectFieldID',
  DateSelectMode: 'DateSelectMode',
  ObjectFieldID_Name: 'ObjectFieldID.Name',
  ObjectFieldID_IsKey: 'ObjectFieldID.IsKey',
  ObjectFieldID_IsFile: 'ObjectFieldID.IsFile',
  ObjectFieldID_IsArray: 'ObjectFieldID.IsArray',
  ObjectFieldID_FieldType: 'ObjectFieldID.FieldType',
  ObjectFieldID_StringLength: 'ObjectFieldID.StringLength',
  ObjectFieldID_NumberScale: 'ObjectFieldID.NumberScale',
  ObjectFieldID_IsTimeAllowed: 'ObjectFieldID.IsTimeAllowed',
  ObjectFieldID_NumberPrecision: 'ObjectFieldID.NumberPrecision',
  ObjectFieldID_IsDisplayField: 'ObjectFieldID.IsDisplayField',
  ObjectFieldID_LinkedObjectID: 'ObjectFieldID.LinkedObjectID',
  ObjectFieldID_LinkedObjectID_Name: 'ObjectFieldID.LinkedObjectID.Name',
  ObjectFieldID_LinkedObjectID_FormForSelectByDefault: 'ObjectFieldID.LinkedObjectID.FormForSelectByDefault',
  ObjectFieldID_LinkedObjectID_FormForSelectByDefault_Type: 'ObjectFieldID.LinkedObjectID.FormForSelectByDefault.Type',
  ObjectFieldID_LinkedObjectID_FormForSelectByDefault_Name: 'ObjectFieldID.LinkedObjectID.FormForSelectByDefault.Name',

  ObjectFieldID_DisplayObjectFieldID: 'ObjectFieldID.LinkedObjectID.DisplayObjectFieldID',
  ObjectFieldID_DisplayObjectFieldID_Name: 'ObjectFieldID.LinkedObjectID.DisplayObjectFieldID.Name',
  ObjectFieldID_DisplayObjectFieldID_FieldType: 'ObjectFieldID.LinkedObjectID.DisplayObjectFieldID.FieldType',

  ObjectFieldID_KeyObjectFieldID: 'ObjectFieldID.LinkedObjectID.KeyObjectFieldID',
  ObjectFieldID_KeyObjectFieldID_Name: 'ObjectFieldID.LinkedObjectID.KeyObjectFieldID.Name',
  ObjectFieldID_KeyObjectFieldID_FieldType: 'ObjectFieldID.LinkedObjectID.KeyObjectFieldID.FieldType',

  ObjectID_DisplayObjectFieldID_Name: 'ObjectID.DisplayObjectFieldID.Name',

  LanguageID_Code: 'LanguageID.Code',
  LanguageID: 'LanguageID',

  Summary: 'Summary',
  Description: 'Description',
  VersionDate: 'VersionDate',
  Version: 'Version',
  InsUser: 'InsUser',
  InsDate: 'InsDate',
  ModUser: 'ModUser',
  ModDate: 'ModDate',

  DisplayField: 'DisplayField',
  GroupID: 'GroupID',
  KeyObjectFieldID: 'KeyObjectFieldID',
  KeyObjectFieldID_Name: 'KeyObjectFieldID.Name',
  DisplayObjectFieldID: 'DisplayObjectFieldID',
  DisplayObjectFieldID_Name: 'DisplayObjectFieldID.Name',
  GroupID_ParentGroupID: 'GroupID.ParentGroupID',
  GroupID_Title: 'GroupID.Title',
  Title: 'Title',
  TitlePath: 'TitlePath',
  IsShowTitle: 'IsShowTitle',
  IsReadOnly: 'IsReadOnly',
  IsDownloadLink: 'IsDownloadLink',
  IsMultiselect: 'IsMultiselect',
  TextFont: 'TextFont',
  TextFontSize: 'TextFontSize',
  IsVisible: 'IsVisible',
  IsMultiValueField: 'IsMultiValueField',
  IsOneValuePerLine: 'IsOneValuePerLine',
  TagColorScheme: 'TagColorScheme',
  TagStylingMode: 'TagStylingMode',
  LookupTagColorSchemeFieldName: 'LookupTagColorSchemeFieldName',
  LookupTagStylingModeFieldName: 'LookupTagStylingModeFieldName',
  IsUseTime: 'IsUseTime',
  SortByDefault: 'SortByDefault',
  AppObjectID: 'AppObjectID',
  AppObjectID_Name: 'AppObjectID.Name',
  IsKey: 'IsKey',
  FieldType: 'FieldType',
  LineCount: 'LineCount',
  ViewFormat: 'ViewFormat',
  EditFormat: 'EditFormat',
  KeyDictionaryField: 'KeyDictionaryField',
  ButtonType: 'ButtonType',
  Position: 'Position',
  IconType: 'IconType',
  ActionFormID: 'ActionFormID',
  IsStartToolbarSection: 'IsStartToolbarSection',
  ActionFormViewMode: 'ActionFormID.ViewMode',
  Type: 'Type',
  EditMode: 'EditMode',
  ParentGroupID: 'ParentGroupID',
  FieldTitleDefaultWidth: 'FieldTitleDefaultWidth',
  IsGroupBodyScrollable: 'IsGroupBodyScrollable',
  TitleWidth: 'TitleWidth',
  FieldWidth: 'FieldWidth',
  Orientation: 'Orientation',
  GroupType: 'GroupType',
  TabPosition: 'TabPosition',
  IsCollapsed: 'IsCollapsed',
  ObjectFieldID_IsRequired: 'ObjectFieldID.IsRequired',
  ViewMode: 'ViewMode',
  IsDisplayField: 'IsDisplayField',
  IsShowLastValues: 'IsShowLastValues',
  LastValuesCount: 'LastValuesCount',
  DisplayType: 'DisplayType',
  Icon: 'Icon',
  IconColor: 'IconColor',
  Style: 'Style',
  MultiselectViewCount: 'MultiselectViewCount',
  DetailFormID: 'DetailFormID',
  DetailFormID_Type: 'DetailFormID.Type',
  DetailFormID_Name: 'DetailFormID.Name',
  MasterObjectFieldID: 'MasterObjectFieldID',
  DetailObjectFieldID: 'DetailObjectFieldID',
  MasterObjectFieldID_Name: 'MasterObjectFieldID.Name',
  DetailObjectFieldID_Name: 'DetailObjectFieldID.Name',
  EventTypeID: 'EventTypeID',
  FormFieldID: 'FormFieldID',
  FormFilterFieldID: 'FormFilterFieldID',
  FunctionName: 'FunctionName',
  FormButtonID: 'FormButtonID',
  CustomParameters: 'CustomParameters',
  CustomOperation: 'CustomOperation',
  LinkedObjectID: 'LinkedObjectID',
  LinkedObjectID_Name: 'LinkedObjectID.Name',
  LinkedObjectID_DisplayObjectFieldID: 'LinkedObjectID.DisplayObjectFieldID',
  LinkedObjectID_DisplayObjectFieldID_Name: 'LinkedObjectID.DisplayObjectFieldID.Name',
  LinkedObjectID_DisplayObjectFieldID_FieldType: 'LinkedObjectID.DisplayObjectFieldID.FieldType',
  LinkedObjectID_KeyObjectFieldID: 'LinkedObjectID.KeyObjectFieldID',
  LinkedObjectID_KeyObjectFieldID_Name: 'LinkedObjectID.KeyObjectFieldID.Name',
  LinkedObjectID_KeyObjectFieldID_FieldType: 'LinkedObjectID.KeyObjectFieldID.FieldType',
  RegionalLocale: 'RegionalLocale',
  AppLang: 'AppLang',
  AppTheme: 'AppTheme',
  AppThemeDrawer: 'AppThemeDrawer',
  AppThemeTopBar: 'AppThemeTopBar',
  AppDrawerSide: 'AppDrawerSide',
  ParentFieldID: 'ParentFieldID',
  ParentFieldID_Name: 'ParentFieldID.Name',
  ParentFieldID_Type: 'ParentFieldID.Type',
  TitlePosition: 'TitlePosition',
  TitleFont: 'TitleFont',
  TitleFontSize: 'TitleFontSize',
  TitleColor: 'TitleColor',
  TitleAlignment: 'TitleAlignment',
  TitleOrientation: 'TitleOrientation',
  TextAlignment: 'TextAlignment',
  Text: 'Text',
  TitleFontStyle: 'TitleFontStyle',
  TitleType: 'TitleType',
  ParameterFieldName: 'ParameterFieldName',
  ReportURL: 'ReportURL',
  MainGroupID: 'MainGroupID',
  NestedFieldID: 'NestedFieldID',
  NestedFieldID_Name: 'NestedFieldID.Name',
  RelationType: 'RelationType',
  IsShowInMainForm: 'IsShowInMainForm',
  IsCustomizable: 'IsCustomizable',
  ActionFormID_Type: 'ActionFormID.Type',
  ActionFormID_Name: 'ActionFormID.Name',
  ActionFormID_ObjectID_Name: 'ActionFormID.ObjectID.Name',
  DockingSide: 'DockingSide',
  IsRequired: 'IsRequired',
  IsShowRoot: 'IsShowRoot',
  TreeIconField: 'TreeIconField',
  TreeTextField: 'TreeTextField',
  CheckField: 'CheckField',
  RootName: 'RootName',
  DisplayCustomParam: 'DisplayCustomParam',
  TotalFunction: 'TotalFunction',
  DateFrom: 'DateFrom',
  DateTo: 'DateTo',
  EndValueField: 'EndValueField',
  IsShowTotals: 'IsShowTotals',
  TotalRowKind: 'TotalRowKind',
  ButtonID: 'ButtonID',
  SourceFieldID: 'SourceFieldID',
  DestinationFieldID: 'DestinationFieldID',
  DestinationFilterID: 'DestinationFilterID',
  SourceFieldID_Name: 'SourceFieldID.Name',
  SourceAppObjectFieldName: 'SourceAppObjectFieldName',
  DestinationFieldID_Name: 'DestinationFieldID.Name',
  DestinationFilterID_Name: 'DestinationFilterID.Name',
  DestAppObjectFilterName: 'DestAppObjectFilterName',
  DetailFormID_ObjectID_Name: 'DetailFormID.ObjectID.Name',
  DestinationFilterID_IsMultiselect: 'DestinationFilterID.IsMultiselect',
  IconSize: 'IconSize',
  IsHideInOtherActions: 'IsHideInOtherActions',
  SelectFormFilter: 'SelectFormFilter',
  DestinationFilter: 'DestinationFilter',
  SourceField: 'SourceField',
  PaginationType: 'PaginationType',
  RowsPerPage: 'RowsPerPage',
  Page: 'Page',
  Splitter: 'Splitter',
  RowSelectionMode: 'RowSelectionMode',
  IsUseHierarchyRequest: 'IsUseHierarchyRequest',
  HierarchyRequestKind: 'HierarchyRequestKind',
  HierarchyChildLevel: 'HierarchyChildLevel',
  HierarchyParentColumn: 'HierarchyParentColumn',
  HierarchyHasChildRowsColumn: 'HierarchyHasChildRowsColumn',
  FilterBody: 'FilterBody',
  IsUserByDefault: 'IsUserByDefault',
  IsFavorite: 'IsFavorite',
  LastModifyDate: 'LastModifyDate',
  MasterFormID: 'MasterFormID',
  IsEditButtonByDefault: 'IsEditButtonByDefault',
  DocURL: 'DocURL',
  Hotkey: 'Hotkey',
  Hint: 'Hint',
  HelpArticleGuid: 'HelpArticleGuid',
  IsAsyncLoad: 'IsAsyncLoad',
  IsActive: 'IsActive',
  LookupSortFieldNames: 'LookupSortFieldNames',
  BorderStyle: 'BorderStyle',
  TextFontStyle: 'TextFontStyle',
  IsAdaptiveHeight: 'IsAdaptiveHeight',
  Height: 'Height',
  MaxHeight: 'MaxHeight',
  IsDoNotSend: 'IsDoNotSend'
} as const;

export const system = {
  _Changed: '_Changed',
  THEME: {
    LIGHT: 'LIGHT',
    BLUE: 'BLUE'
  },
  ButtonsColumn: '__ButtonsColumn',
  ColumnSpacer: '__columnSpacer',
  InlineEditColumn: '__InlineEditColumn',
  HasChildren: '_hasChildRecords',
  DIRECTION: {
    LTR: 'ltr',
    LEFT: 'left',
    RIGHT: 'right'
  },
  VIEW_MODE: {
    FULL_SCREEN: 1,
    MODAL: 2,
    IN_NEW_WINDOW: 3
  },
  MIN_PASSWORD_LENGTH: 5,
  EDIT_MODE: {
    FORM: 0,
    INLINE: 1,
    CELL: 2,
    CHECK: 3
  },
  FORM_STATUS: {
    INIT: 'init',
    SHOW: 'show'
  },
  FORM_EDIT_CREATE_MODE: {
    ADD: 'add',
    ADD_CHILD: 'addChild',
    EDIT: 'edit',
    VIEW: 'view',
    MULTI_EDIT: 'multiEdit',
    REPORT: 'report',
    DELETE: 'delete',
    FORM_SELECT: 'formSelect',
    FORM_MULTI_SELECT: 'formMultiSelect',
    FORM_LIST: 'listForm',
    FILTER_MODAL: 'filterModal',
    QR_MODAL: 'qrModal'
  },
  LOAD_ALL_CONSTANTS: {
    LOAD_ALL_SETTINGS: 'loadAllSettings',
    DEFAULT_SCROLLING_SETTINGS: 'defaultScrollingSettings'
  },
  FORM_ORIENTATION: {
    HORIZONTAL: 1,
    VERTICAL: 2
  },
  LANGUAGE: 'language',
  LANGUAGES: {
    RUSSIAN: 'Русский',
    UKRAINIAN: 'Українська',
    ENGLISH: 'English'
  },
  USER_SETTINGS: {
    GENERAL_USER: 'general_user',
    APP_SETTINGS: 'app_settings',
    TITLE: 'title',
    REGIONAL_SETTINGS: 'regionalsettings',
    EDITOR_STYLING_MODE: 'editorStylingMode',
    APPLICATION_LANG: 'APPLICATION_LANG',
    ALTER_LINES_COLOR: 'alt_colors',
    HORIZ_SEPAR_LINES: 'horizontal_separate_lines',
    VERT_SEPAR_LINES: 'vertical_separate_lines',
    NEW_WINDOW_OPENED_MODE: 'new_window_opened_mode',
    UI_VERSION: 'ui_version'
  },
  SYSTEM_BUTTONS: {
    TOGGLE_TOTALS: 'toggleTotals',
    TOGGLE_TOTALS_BY_SELECTED: 'toggleTotalsBySelected',
    TOGGLE_TOTALS_ALL_DATA: 'toggleTotalsAllData',
    EXPORT_ALL: 'exportAll',
    EXPORT_SELECTED: 'exportSelected',
    COPY_FILTER_AS_URL: 'copyFilterAsURL'
  },
  NUMBER_OF_RECENT_FILTER_TEMPLATES: 5,
  FILE_NOT_FOUND: 'FILE_NOT_FOUND',
  FUNCTION_NOT_FOUND: 'FUNCTION_NOT_FOUND',
  USER_NOT_FOUND: 'UserNotFoundError',
  uid: 'uid',
  login: 'login',
  page404: 'page404',
  personID: 'personID',
  current_user: 'current_user',
  is_new_tab: 'is_new_tab',
  ReturnUrl: 'return_url',
  FILTER_QUERY: 'FILTER_QUERY',
  app: 'app_settings',
  is_sing_out: 'is_sing_out',
  FIELD_TYPE: {
    TEXT: 0,
    NUMBER: 1,
    DATE: 2,
    BOOL: 3,
    MEMO: 5,
    FILE: 6,
    BUTTON: 'button',
    DECORATION: 'decoration',
    COMBINED: 'combined'
  },
  BORDER_STYLE: {
    DEFAULT: 0,
    UNDERLINE: 1,
    NONE: 2
  },
  TITLE_POSITION: {
    LEFT: 1,
    RIGHT: 2,
    TOP: 0,
    ONBORDER: 3,
    FLOATING: 4
  },
  TITLE_LOCATION: {
    LEFT: 'left',
    RIGHT: 'right',
    TOP: 'top',
    ONBORDER: 'on_border',
    FLOATING: 'floating'
  },
  TITLE_ORIENTATION: {
    HORIZONTAL: 0,
    VERTICAL: 1
  },
  TITLE_TYPE: {
    ICON: 1,
    TITLE: 2,
    ICON_AND_TITLE: 3
  },
  TAB_POSITION: {
    TOP: 0,
    LEFT: 1,
    RIGHT: 2,
    BOTTOM: 3
  },
  USER_SCRIPTS_INITIATOR: {
    TABLE: 'table',
    TREE: 'tree',
    FORM_EDIT: 'formEdit'
  },
  DEF_FE_BUTTON_EVENTS: {
    SUBMIT: 'submit',
    APPLY: 'apply',
    CANCEL: 'cancel'
  },
  DX_CONTROLS: {
    dxTextArea: 'dxTextArea',
    dxTextBox: 'dxTextBox',
    dxNumberBox: 'dxNumberBox',
    dxDateBox: 'dxDateBox',
    dxCheckBox: 'dxCheckBox',
    dxButton: 'dxButton',
    dxSelectBox: 'dxSelectBox',
    dxTagBox: 'dxTagBox',
    dxHtmlEditor: 'dxHtmlEditor',
    dxFileUploader: 'dxFileUploader',
    dxSwitch: 'dxSwitch',
    dxImage: 'dxImage',
    dxDropDownBox: 'dxDropDownBox',
    extQrScanner: 'extQrScanner',
    dxDiagram: 'dxDiagram,',
    dxRangeSlider: 'dxRangeSlider',
    dxSlider: 'dxSlider',
    dxListSelector: 'dxListSelector',
    extDualListSelector: 'extDualListSelector',
    extFileViewer: 'extFileViewer',
    dxColorBox: 'dxColorBox',
    dxCodeEditor: 'dxCodeEditor',
    dxBooleanSelector: 'dxBooleanSelector',
    dxRadioGroup: 'dxRadioGroup',
    dxButtonGroup: 'dxButtonGroup',
    dxLabel: 'dxLabel',
    dxTagList: 'dxTagList'
  },
  FORM_STATE_PROPS: {
    FIELDS: 'fields'
  },
  TOOLBAR_BTN_CLASSES: {
    EDIT: 'table-form-edit-button',
    MULTI_EDIT: 'table-form-multi-edit-button',
    DEL: 'table-form-delete-button',
    SELECT: 'table-form-select-all',
    DESELECT: 'table-form-deselect-all',
    GOTO: 'table-form-goto-button',
    REPORTS: 'table-form-reports-button',
    FILTER: 'table-form-dock-filter-button',
    ADD: 'table-form-add-button',
    REFRESH: 'table-form-refresh-button',
    EXPORT: 'table-form-export-button',
    COPY: 'table-form-copy-button',
    CLEAR_QUICK_FILTER: 'clear-quick-filter',
    SET_IS_PASSED: 'set-is-passed',
    CLEAR_IS_PASSED: 'clear-is-passed',
    ADD_CHILD: 'table-form-add-child',
    HIDDEN_MENU_ITEM: 'd5-hidden-menu-item',
    REPORT_GROUP: 'table-form-report-group',
    SHOW_FILTER_DOCK_PANEL: 'show-filter-dock-panel',
    SHOW_EDIT_DOCK_PANEL: 'show-edit-dock-panel'
  },
  MAIN_TOP_CONTENT_BUTTONS: {
    ProfileSettings: 'ProfileSettings',
    UserSettings: 'UserSettings',
    ShowAllSubsystems: 'ShowAllSubsystems',
    ShowMobileVersionSubsystems: 'ShowMobileVersionSubsystems',
    SignOut: 'SignOut',
    ShowAdminPanel: 'ShowAdminPanel',
    ShowMainPage: 'ShowMainPage',
    ShowConfiguratorPanel: 'ShowConfiguratorPanel',
    OwnerConsole: 'OwnerConsole',
    ShowSingleForm: 'ShowSingleForm',
    ShowRegularForm: 'ShowRegularForm',
    UserScriptGuide: 'UserScriptGuide',
    StartWork: 'StartWork',
    CreateProject: 'CreateProject',
    Orders: 'Orders',
    Groups: 'Groups',
    CreateList: 'CreateList',
    Filter: 'Filter',
    EditFilters: 'EditFilters',
    Documentation: 'Documentation',
    ChangePassword: 'ChangePassword',
    About: 'About',
    Errors: 'Errors',
    Russian: 'Russian',
    English: 'English',
    Ukrainian: 'Ukrainian'
  },
  LAYOUT_CLASSNAMES: {
    TABLE_CN: '--table',
    SUBFORMS_CN: '--subforms',
    DECORS_CN: '--decors',
    FIELDS_CN: '--fields',
    HIDDEN_CN: '--hidden',
    DEFAULT_CN: '--default',
    EMPTY_CN: '--empty',
    ACCORDION_CN: '--accordion',
    TAB_PANEL_CN: '--tab-panel',
    ONLY_SMALL_ITEMS_CN: '--only-small-items',
    HAS_ACCORDION_CN: '--has-accordion',
    HAS_FILE_VIEWER_CN: '--has-file-viewer',
    EDIT_SUBFORM_CN: '--edit-subform',
    RESIZABLES: '--resizable-items',
    GROUP_CN: 'group',
    ITEM_CN: 'item',
    FORM_EDIT: 'form-edit',
    HAS_GROUP_SCROLL: 'has-group-scroll',
    D5_SELECTED_CELL: 'd5-selected-cell'
  },
  HEADER_ICON_COLOR: {
    FILTERS_SELECTED: '#0049FF',
    FILTERS_NOT_SELECTED: '#6E7492'
  },
  LINKS: {
    TYPES: {
      LINK: 'Link',
      FORM: 'Form',
      GOTO: 'Goto'
    },
    OPEN: {
      MODAL: 'Modal',
      FULL_SCREEN: 'FullScreen',
      IN_NEW_WINDOW: 'InNewWindow',
      IN_NEW_TAB: 'InNewTab',
      IN_CURRENT: 'InCurrent'
    }
  },
  API_OPERATION: {
    CREATE: 'Ins',
    READ: 'List',
    UPDATE: 'Mod',
    DELETE: 'Del'
  },
  SYSTEM_PREFIX: '_system_',
  ENUM_DS: {
    VALUE_EXPR: 'key',
    DISPLAY_EXPR: 'value'
  },
  TEMPLATE_TYPE: {
    BUTTON: 'button',
    TITLE: 'title'
  },
  ORIENTATION: {
    NUM: {
      COLUMN: 1,
      ROW: 2
    },
    STR: {
      COLUMN: 'col',
      ROW: 'row'
    }
  },
  LAYOUT_TYPE: {
    FILTER: 'filter',
    SUBFORM: 'subform',
    DECORATION: 'decoration',
    FORM_FIELD: 'form_field',
    BUTTON: 'button',
    GROUP: 'group',
    TABLE: 'table',
    KANBAN: 'kanban'
  },
  CHANGE_EVENT: 'change',
  EDITOR_CLASSES: {
    FIELD_CONTAINER: 'field-container',
    D5FIELD: 'd5-field',
    FC_BETWEEN: 'field-container-between'
  },
  MSGS: {
    MustImplInAChild: 'Must be implemented in a child'
  },
  VALIDATION_GROUPS: {
    FORM_EDIT: 'form_edit',
    LAYOUT: {
      FILTER: 'layout-filter'
    }
  },
  DISPLAY_TYPE: {
    TEXT: 0,
    LINK: 1,
    IMAGE: 2,
    PASSWORD: 3,
    PERIOD: 4,
    ICON: 5,
    FILE: 6,
    HTML_EDITOR: 7,
    SWITCH: 8,
    DROP_DOWN_BOX: 9,
    ENUMERATOR: 10,
    QR_SCANNER: 11,
    LOOKUP: 12,
    DIAGRAM: 13,
    SLIDER: 14,
    RANGE_SLIDER: 15,
    LIST_SELECTOR: 16,
    DUAL_LIST_SELECTOR: 17,
    COLOR_BOX: 18,
    LABEL: 19,
    FILE_VIEWER: 20,
    CODE_EDITOR: 21,
    BOOLEAN_SELECTOR: 22,
    RADIO_GROUP: 23,
    BUTTON_GROUP: 24
  },
  RIGHT_BAR_WIDTH: 35,
  MAX_DISPLAYED_TAGS: 3,
  FILE_NAMES: {
    CONFIG: 'config',
    DIAGRAM_CONFIG: 'diagram-config'
  },
  DISPLAY_CUSTOM_PARAMS: {
    PARENT_FIELD: 'ParentField'
  },
  CONFIG_PARAMS: {
    APPLICATION: 'application',
    HOME_PAGE: 'homePage',
    AUTH_TYPE: 'authType',
    ALLOW_CHANGE_THEME: 'isAllowChangeTheme',
    CUSTOM_PAGE: 'customPage',
    DEFAULT_FILE: 'defaultFile',
    DOC_URL: 'docURL',
    FACEBOOK: 'facebook',
    FAVICON: 'favicon',
    GOOGLE: 'google',
    LINKEDIN: 'linkedIn',
    LOGIN_PAGE: 'loginPage',
    REGISTRATION_ALLOWED: 'registrationAllowed',
    REGISTRATION_TYPE: 'registrationType',
    USER_SETTINGS_IS_LOADED: 'userSettingsIsLoaded',
    TITLE: 'title',
    WINDOW_TITLE: 'windowTitle',
    CUSTOM_FONTS: 'customFonts',
    MESSAGE_TIMEOUTS: 'messageTimeouts',
    SUCCESS_MESSAGE_TIMEOUT: 'successMessageTimeout',
    ERROR_MESSAGE_TIMEOUT: 'errorMessageTimeout'
  },
  FONTAWESOME: {
    FA_GOOGLE: 'fab fa-google',
    FA_FACEBOOK: 'fab fa-facebook-f',
    FA_LINKEDIN: 'fab fa-linkedin-in'
  },
  COMPONENT_CLASSES: {
    d5LinkClass: 'd5-link dx-link link-control',
    IS_PRESSED_CLASS: 'd5-is-pressed'
  },
  DEFAULT_LOCALS: {
    LINE_HEIGHT: 1.32,
    MESSAGE_TIMEOUT: 3000,
    MESSAGE_TIMEOUT_LONG: 999999999,
    DEFAULT: 'default',
    PRECISION: 0,
    CELL_INNER_PADDING: '5px'
  },
  INPUT_PLACEHOLDERS: {
    PASSWORD: 'Password',
    NAME: 'Name',
    EMAIL: 'Email',
    LOGIN: 'Login',
    CONFIRMATION: 'Confirmation',
    EMAIL_LOGIN: 'Email_Login'
  },
  LOGIN_PAGE_CONSTANTS: {
    ID: {
      LOGIN_FIELD: 'loginField',
      EMAIL_LOGIN_FIELD: 'emailLoginField',
      PASSWORD_FIELD: 'passwordField',
      CONFIRM_FIELD: 'confirmField'
    }
  },
  ROW_SELECTION_MODE: {
    REGULAR: 'single',
    CHECK_BOX: 'multiple',
    REGULAR_NUM: 1,
    CHECK_BOX_NUM: 2
  },
  TREE_REFRESH_NODE_CASE: {
    EMPTY_PARENT: 'emptyParent',
    HAS_ID: 'hasID',
    HAS_PARENT: 'hasParent'
  },
  SYS_LOGIN: 'Sys_Login',
  SYS_PROFILE: 'Sys_Profile',
  SYS_HELP_ARTICLES: 'Sys_HelpArticles',
  REQUEST: 'Request',
  HAS_DEFAULT_FILTER: 'has-default-filter',
  CONTROL_SEARCH_MODE: 'control-searchMode',
  CONTROL_DISPLAY_MODE: 'control-displayMode',
  CONTROL_LAST_VALUES: 'control-last-values',
  IS_MOBILE: 'is-mobile',
  QR_CAM: 'qr-cam',
  VISIBLE_FILTERS: 'visible-filters',
  MASTER_FORM_ID: 'MasterFormID',
  MASTER_FORM_NAME: 'MasterFormName',
  SERVICE_PREFIX: {
    ADMIN: 'admin',
    CONFIGURATOR: 'configurator',
    FORM: 'form'
  },
  COMBINED_COLUMN_KEYS: {
    TREE_TEXT_FIELD: 'treeTextField',
    TREE_ICON_FIELD: 'treeIconField',
    TREE_CHECK_FIELD: 'treeCheckField'
  },
  SUBFORM_POSITION: {
    AUTO: 1,
    FILTER_DOCK_PANEL: 2
  },
  LOCALE_SEPARATORS: {
    ru: {
      thousandSeparator: ' ',
      fractionalSeparator: ','
    },
    uk: {
      thousandSeparator: ' ',
      fractionalSeparator: ','
    },
    en: {
      thousandSeparator: ',',
      fractionalSeparator: '.'
    }
  },
  QUICK_TIPS: 'quick_tips'
} as const;

export const LOGIN_PAGE_US = {
  [system.CONFIG_PARAMS.CUSTOM_PAGE]: null,
  [system.CONFIG_PARAMS.AUTH_TYPE]: {
    [system.CONFIG_PARAMS.GOOGLE]: false,
    [system.CONFIG_PARAMS.FACEBOOK]: false,
    [system.CONFIG_PARAMS.LINKEDIN]: false
  },
  [system.CONFIG_PARAMS.REGISTRATION_ALLOWED]: false,
  [system.CONFIG_PARAMS.REGISTRATION_TYPE]: {
    [system.CONFIG_PARAMS.GOOGLE]: false,
    [system.CONFIG_PARAMS.FACEBOOK]: false,
    [system.CONFIG_PARAMS.LINKEDIN]: false
  }
} as const;

export const SIMPLIFIED_LAYOUT_QUERY = 'simpleview';
export const SUBSYSTEM_STR = 'section';
export const FORM_PREVIEW = 'form-preview';
export const KANBAN_URL = 'kanban';
export const NAV_EDIT_PART = 'nav-edit';
export const LIST_PART = 'list';
export const FILTER_URL = 'filter';
export const RESTORE_PASSWORD = '/restore-password';
export const USER_ACTIVATION = '/user-activation';
export const LOGIN_ROUTE = '/login';
export const ADMIN_PANEL_ROUTE = '/admin';
export const USER_SCRIPT_ROUTE = '/userscript-guide/docs';
export const CONFIGURATOR_PANEL_ROUTE = '/configurator';
export const SINGLE_FORM = '/form';
export const SECTION_ROUTE = '/' + SUBSYSTEM_STR;
export const REPORT_VIEWER_STR = 'wapi/dxreport/viewer';
export const FILE_PREVIEW_FORM = 'FilePreviewForm';
export const HELP_ARTICLE_FORM = 'HelpArticleForm';
export const NOT_FOUND_ROUTE = '404';
export const DEV_TEST_ROUTE = 'test';
export const MIN_MAX_DATE = {
  MIN: new Date(1900, 0, 1),
  MAX: new Date(3000, 0, 1)
};

export const UI_VERSION = {
  DESKTOP: 0,
  MOBILE: 1,
  DESKTOP_AND_MOBILE: 2
};

export const DEFAULT_APPLICATION_NAME = 'default';
