import {createSlice} from '@reduxjs/toolkit';
import {modal, open} from './actions';
import {system} from '../objects';
import {D5ID, FormCreateMode, FormType, FormViewMode} from '../interfaces/global-interfaces';
import {ExtendedPromise} from '../SecondaryMethods/extendedPromise';
import {FileDesc} from '../../components/newReactFormFields/FilePreview/FilePreviewControl';

type EditFormsModalOptions = {
  formType: FormType.EDIT | FormType.FREE_FORM | FormType.MULTI_EDIT;
  options?: undefined;
};

type NavEditFormModalOptions = {
  formType: FormType.NAV_EDIT;
  options?: undefined;
};

type PreviewFormModalOptions = {
  formType: FormType.FormPreview;
  options?: undefined;
};

type FilterFormModalOptions = {
  formType: FormType.FILTER_FORM;
  options?: undefined;
};

type ReportModalOptions = {
  formType: FormType.REPORT;
  options: {
    isCalledByReportGroup: boolean;
    reportName: string;
    selectedKeys: Array<number>;
  };
};

type ListFormModalOption = {
  formType: FormType.LIST | FormType.TREE;
  options: {
    defaultFilter?: string;
  };
};

export type FilePreviewModalOption = {
  formType: FormType.FILE_PREVIEW_FORM;
  options: {
    id?: string;
    objectFieldIDName?: string;
    fileMeta: FileDesc
    operationsParams?: Record<string, unknown>;
  };
};
export type HelpArticlePopupOption = {
  formType: FormType.HELP_ARTICLE_MODAL;
  options: {
    helpArticleGuid: string;
    helpArticleTitle: string;
    subsystemDocUrl?: string;
    docUrl?: string;
    formID: string;
  }
};

export type ModalOptions =
  | ReportModalOptions
  | ListFormModalOption
  | FilePreviewModalOption
  | EditFormsModalOptions
  | NavEditFormModalOptions
  | PreviewFormModalOptions
  | FilterFormModalOptions
  | HelpArticlePopupOption;

export type ModalStateItem = {
  actionFormId: string;
  actionFormName?: string;
  parentFormID?: string;
  parentFormKey?: string;
  id?: D5ID[];
  formType: FormType;
  createMode?: FormCreateMode;
  viewMode?: FormViewMode;
  closePromise: ExtendedPromise;
} & ModalOptions;

const initialState: ModalStateItem[] = [];

const removeModal = (state: ModalStateItem[], id: D5ID) => {
  const modalIndex = state.findIndex(el => el.actionFormId === id);
  if (~modalIndex) {
    state.splice(modalIndex, 1);
  }
};

const formsSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(open, (state, {payload}) => {
        const modalExists = state.some(el => el.actionFormId === payload.actionFormId);
        if (!modalExists && payload.viewMode === system.VIEW_MODE.MODAL) {
          state.push(payload as ModalStateItem);
        }
      })
      .addCase(modal.close, (state, {payload}) => {
        removeModal(state as ModalStateItem[], payload);
      })
      .addCase(modal.closeForms, (state, {payload}) => {
        payload.ids.forEach(id => {
          removeModal(state as ModalStateItem[], id);
        });
      })
      .addCase(modal.closeAllModals, state => {
        const ids = state.map(({actionFormId}) => actionFormId);
        ids.forEach(id => {
          removeModal(state as ModalStateItem[], id);
        });
      });
  }
});

export const modalReducer = formsSlice.reducer;
