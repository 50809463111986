export const getOrientation = (orientation: number) => {
  switch (orientation) {
    case 1:
      return 'vertical';
    case 2:
      return 'horizontal';
    default:
      return 'horizontal';
  }
};

export const getWidthOrHeight = (orientation: 'vertical' | 'horizontal', length: string = '100%') => {
  switch (orientation) {
    case 'vertical':
      return {
        height: length
      };
    case 'horizontal':
      return {
        width: length
      };
    default:
      return {
        width: length
      };
  }
};
