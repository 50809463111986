import {forwardRef, useEffect, useState} from 'react';
import Resizable from 'devextreme-react/resizable';
import {useFormStateManager} from '../forms/FormStateManager';
import useCalculatedHeight from 'components/newReactFormFields/useCalculatedHeight';

const style = {
  width: '100%'
};

export const DEFAULT_HEIGHT = 450;
export const DEFAULT_RESIZABLE_MIN_HEIGHT = 150;
const DEFAULT_MAX_HEIGHT_FOR_DIALOGS_PROMPT = 150;
export const DEFAULT_MIN_HEIGHT = 32;
const DEFAULT_MIN_WIDTH = 100;
const HEIGHT = '100%';
const WIDTH = '100%';

/**
 * @typedef {Object} HOCResizableOptions
 * @property {number} [defaultHeight]
 * @property {number | null} [maxHeight]
 * @property {number | null} [maxWidth]
 * @property {number | null} [minWidth]
 * @property {number | null} [minHeight]
 * @property {number | null} [height]
 * @property {number | null} [width]
 * @property {function | null} [updateDimensions]
 * @property {function | null} [setDynamicSize]
 * @property {string} [handles]
 * @property {string} [classNames]
 * @property {boolean | undefined} [isRowsHeight]
 * @property {number | undefined} [defaultRows]
 * @property {number | undefined} [defaultRowHeight]
 */

/**
 * @param Component
 * @param {HOCResizableOptions} [options]
 */
const HOCResizable = (Component, options) => {
  const MAX_WIDTH = options?.maxWidth || window.innerWidth;
  const MIN_WIDTH = options?.minWidth ?? DEFAULT_MIN_WIDTH;
  const MIN_HEIGHT = options?.minHeight ?? DEFAULT_MIN_HEIGHT;
  const HANDLES = options?.handles || 'bottom';
  const ELEMENT_ATTRIBUTE = {
    class: options?.classNames ?? ''
  };

  const HOCResizableComponent = forwardRef((props, ref) => {
    const {
      hasScroll: parentHasScroll,
      options: {isAdaptiveHeight, height, maxHeight}
    } = props;

    const {height: calculatedHeight, maxHeight: calculatedMaxHeight} = useCalculatedHeight({
      parentHasScroll,
      isAdaptiveHeight,
      height,
      maxHeight,
      isRowsHeight: options?.isRowsHeight,
      defaultRows: options?.defaultRows,
      defaultRowHeight: options?.defaultRowHeight
    });
    const DEFAULT_HEIGHT = calculatedHeight > MIN_HEIGHT ? calculatedHeight : MIN_HEIGHT;
    const MAX_HEIGHT = calculatedMaxHeight;

    const fieldName = props.fieldName;
    const isDialogPrompt = props.isDialogPrompt;
    const isQRWidget = props.QRSight;
    const formStateManager = useFormStateManager();
    const storedDimensions = formStateManager?.getFieldDimensions(fieldName);
    const storedQRCam = formStateManager?.getQRCam();
    const storedWidth = storedDimensions?.width;
    const storedHeight = storedDimensions?.height;
    const storedWidthQRCam = storedQRCam?.aimBoxSize;
    const [changedSize, setChangedSize] = useState(storedWidth || storedHeight);
    const [isStarted, setIsStarted] = useState(false);
    const [componentWidth, setComponentWidth] = useState(WIDTH);
    const [componentHeight, setComponentHeight] = useState(HEIGHT);
    const [componentMaxHeight, setComponentMaxHeight] = useState(calculatedMaxHeight);
    const updateDimensionsState = (width, height) => {
      width && setComponentWidth(width);
      height && setComponentHeight(height);
    };

    useEffect(() => {
      if (options?.width && options?.height) {
        updateDimensionsState(options?.width, options?.height);
      }
    }, [options?.height, options?.width]);

    const isVerticalHandle = HANDLES === 'bottom' || HANDLES === 'top';

    return (
      <Resizable
        style={style}
        elementAttr={ELEMENT_ATTRIBUTE}
        keepAspectRatio={isQRWidget}
        onInitialized={({element}) => {
          const calculatedWidth = isQRWidget ? storedWidthQRCam : storedWidth;
          if (storedHeight) {
            return updateDimensionsState(calculatedWidth, storedHeight);
          }

          /* 8 - это марджины и падинги в поле*/
          const textAreaScrollHeight = element.querySelector('textarea')?.scrollHeight + 8;
          const calculatedHeight =
            DEFAULT_HEIGHT || // For HtmlEditor
            (textAreaScrollHeight > MAX_HEIGHT
              ? isDialogPrompt
                ? DEFAULT_MAX_HEIGHT_FOR_DIALOGS_PROMPT
                : MAX_HEIGHT
              : textAreaScrollHeight); // For TextAreaController
          updateDimensionsState(calculatedWidth, calculatedHeight);
        }}
        onResizeStart={() => {
          setIsStarted(true);
        }}
        onResize={({width, height}) => {
          updateDimensionsState(width, height);
        }}
        onResizeEnd={({width, height}) => {
          updateDimensionsState(width, height);
          formStateManager.saveFieldDimensions({fieldName, width, height});
          if (!changedSize) {
            setChangedSize(true);
          }
          if (typeof options?.updateDimensions === 'function') {
            options?.updateDimensions({width, height});
          }
          setIsStarted(false);
        }}
        maxHeight={componentMaxHeight}
        maxWidth={MAX_WIDTH}
        minWidth={MIN_WIDTH}
        minHeight={MIN_HEIGHT}
        //якщо ресайзити можна тільки по вертикалі, то ширину фіксувати не потрібно
        width={isVerticalHandle ? WIDTH : componentWidth}
        height={componentHeight}
        handles={HANDLES}
      >
        <Component
          storedHeight={storedHeight}
          changedSize={changedSize}
          setComponentHeight={setComponentHeight}
          setComponentMaxHeight={setComponentMaxHeight}
          isStarted={isStarted}
          {...props}
          ref={ref}
        />
      </Resizable>
    );
  });
  return HOCResizableComponent;
};

export default HOCResizable;
