import {actionTypes} from './actions';
import {actionTypes as userActions} from '../signUser/actions';
import {fields} from '../objects';
import {Map} from 'immutable';
import {prepareSysSubsystem} from './sysObjectEnchanters';

const userState = {
  Sys_Subsystems: [],
  Sys_Forms: [],
  Sys_SubForms: [],
  formReqHash: Map({}),
  formLoading: [] //массив formID, которые сейчас загружаются
};

function saveSubForms(curSubForms, sysForms) {
  const excludeExistingForms = (currentForms, newForms) =>
    newForms.filter(newForm => !currentForms.some(form => form.ID === newForm.ID));

  let subForms = excludeExistingForms(curSubForms, sysForms.map(form => form.Sys_SubForms || []).flat());
  if (!subForms.length) {
    return curSubForms;
  }
  return [...curSubForms, ...subForms];
}

/**
 * Удаляет со стейта форму и все ее сабформы
 * @param {string} name
 * @param {Object} state
 * @param {SysForm[]} state.Sys_Forms
 * @param {SysSubForm[]} state.Sys_SubForms
 */
function removeForm(name, state) {
  const {Sys_Forms, Sys_SubForms} = state;
  let form = Sys_Forms.find(f => f.Name === name);
  if (!form) return state;
  /** Массив Sys_SubForms.ID текущей формы у которой Sys_Forms.ID === id**/
  const subIds = (form.Sys_SubForms || []).map(sub => sub.ID);
  /** Массив Sys_Forms.ID. Берем все сабформы и получаем их форм ИД**/
  const subFormIds = Sys_SubForms.filter(sub => subIds.includes(sub.ID)).map(sub => sub[fields.DetailFormID_Name]);
  /** Получаем новый массив сабформ, исключая те что в форме которую мы удаляем.**/
  const newSubForms = Sys_SubForms.filter(s => !subIds.includes(s.ID));
  /** Получаем массив сабформ у которых есть ссылки на удаляемые формы.**/
  const formsHaveLinksLeft = newSubForms.filter(f => subFormIds.includes(f[fields.DetailFormID_Name]));

  return {
    ...state,
    Sys_Forms: Sys_Forms.filter(f => {
      /**У формы есть еще ссылка на сабформу **/
      const formHasLink = formsHaveLinksLeft.includes(f.Name);
      /** Текущая форма, которую мы удаляем **/
      const isRemovingForm = f.Name === name;
      /** Удалили сабформу, пробуем удалить и ее форму. **/
      const subFormWasDeleted = subFormIds.includes(f.Name);

      /** Если у формы есть ссылка в сабформах, ее нужно оставить!
       * Если это текущая форма или сабформу удалили, то удаляем. **/

      return !isRemovingForm && (!subFormWasDeleted || (subFormWasDeleted && formHasLink));
    }),
    Sys_SubForms: newSubForms
  };
}

export const requestsReducer = (state = userState, {type, payload}) => {
  const {formName, formID} = payload || {};

  //ключ - formName || formID (formName приоритетнее)
  const key = formName || formID;

  switch (type) {
    case actionTypes.POST_SYS_SUBSYSTEMS_SUCCESS:
      return {
        ...state,
        Sys_Subsystems: removeDuplicates(state.Sys_Subsystems, payload).map(prepareSysSubsystem)
      };
    case actionTypes.POST_SYS_FORM_SUCCESS: {
      const newSysForms = payload.Sys_Forms;
      let newState = newSysForms.reduce((acc, curr) => removeForm(curr.Name, acc), state);

      return {
        ...newState,
        Sys_Forms: [...newState.Sys_Forms, ...newSysForms],
        Sys_SubForms: saveSubForms(newState.Sys_SubForms, newSysForms)
      };
    }
    case actionTypes.ADD_SUB_FROM:
      return {
        ...state,
        Sys_SubForms: [...state.Sys_SubForms, ...payload]
      };
    case actionTypes.REMOVE_SUB_FROM:
      return {
        ...state,
        Sys_SubForms: state.Sys_SubForms.filter(sf => !payload.includes(sf.Name))
      };
    case userActions.SIGN_OUT_SUCCESS:
      return {...state, Sys_Subsystems: []};
    case actionTypes.POST_SYS_FORM_REQUEST:
      return {
        ...state,
        formLoading: [...state.formLoading, key]
      };
    case actionTypes.POST_SYS_FORM_LOADED:
      return {
        ...state,
        formLoading: state.formLoading.filter(formID => formID !== key)
      };
    case actionTypes.SET_SYSFORM_HASH:
      return {
        ...state,
        formReqHash: state.formReqHash.set(payload.sysFormName, payload.hash)
      };
    default:
      return state;
  }
};
const removeDuplicates = (array1, array2) => {
  const arrau = [...array1, ...array2];
  let key = 'ID';
  return arrau.reduce((arr, item) => {
    const removed = arr.filter(i => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};
