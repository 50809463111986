import {fields, system} from 'services/objects';
import {gridConstants} from 'components/Layout/utils/variables';
import {
  ButtonCreators,
  ELayoutType,
  IDecorOptions,
  ILabel,
  ILayoutItem
} from 'services/SecondaryMethods/formItems/itemInterfaces';
import {SysFormDecorationElement} from 'services/interfaces/sysObjects';
import {DECORATION_DISPLAY_TYPE_TEXT} from '../../interfaces/global-interfaces';
import {getTextAlignmentStr, getTitleAlignment, getTranslatedTitle} from './utils';

const {
  MIN_WIDTH: {DECOR_MIN_WIDTH},
  MIN_HEIGHT: {DECOR_MIN_HEIGHT}
} = gridConstants;

const getTitlePosition = (titlePosition: number) => {
  switch (titlePosition) {
    case system.TITLE_POSITION.LEFT:
      return 'left';
    case system.TITLE_POSITION.RIGHT:
      return 'right';
    case system.TITLE_POSITION.ONBORDER:
      return 'on_border';
    case system.TITLE_POSITION.FLOATING:
      return 'floating';
    default:
      return 'top';
  }
};

export const getDecorItems = (
  decorationElements: SysFormDecorationElement[],
  isFormBodyScrollable: boolean
): ILayoutItem<IDecorOptions>[] => {
  return decorationElements.map((item): ILayoutItem<IDecorOptions> => {
    const label: ILabel = {
      text: getTranslatedTitle(item[fields.Translations]) || item[fields.Title],
      visible: !!item[fields.IsShowTitle],
      location: getTitlePosition(item[fields.TitlePosition])
    };

    const decorOptions: IDecorOptions = {
      displayCustomParam: item[fields.DisplayCustomParam]!,
      displayType: item[fields.DisplayType],
      formID: item[fields.FormID],
      lineCount: item[fields.LineCount]!,
      isShowTitle: !!item[fields.IsShowTitle],
      title: getTranslatedTitle(item[fields.Translations]) || item[fields.Title],
      titleFont: item[fields.TitleFont],
      titleFontSize: item[fields.TitleFontSize],
      titleColor: item[fields.TitleColor],
      titleAlignment: getTitleAlignment(item[fields.TitleAlignment]),
      titleFontStyle: item[fields.TitleFontStyle],
      width: item[fields.Width]!,
      hasScroll: isFormBodyScrollable,
      text: item[fields.Text],
      textAlignment: getTextAlignmentStr(item[fields.TextAlignment]),
      textFontStyle: item[fields.TextFontStyle],
      textFontSize: item[fields.TextFontSize],
      label,
      isResizable: false,
      isAdaptiveHeight: !!item[fields.IsAdaptiveHeight],
      height: item[fields.Height],
      maxHeight: item[fields.MaxHeight]
    };

    return {
      creator: ButtonCreators.FORM,
      groupID: item[fields.GroupID],
      id: item[fields.ID],
      isVisible: Boolean(item[fields.IsVisible]),
      itemType: ELayoutType.DECORATION,
      displayType: item[fields.DisplayType],
      name: item[fields.Name],
      cols: item[fields.Cols],
      rows: item[fields.Rows],
      minWidth: DECOR_MIN_WIDTH,
      minHeight: DECOR_MIN_HEIGHT,
      className: `item-margin d-layout-item decor-${
        DECORATION_DISPLAY_TYPE_TEXT[decorOptions.displayType]
      } label-text-color -decor`,
      idAttr: `decoration-${item[fields.Name]}`,
      options: decorOptions,
      order: item[fields.Order],
      parentID: item[fields.GroupID]!
    };
  });
};
