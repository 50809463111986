import {system} from 'services/objects';

const {
  FIELD_TYPE: {TEXT, NUMBER, BOOL, DATE},
} = system;

export const COLUMN_WIDTH = {
  [TEXT]: {
    MIN: 30,
    DEFAULT: 100,
  },
  [DATE]: {
    MIN: 30,
    DEFAULT: 60,
  },
  [NUMBER]: {
    MIN: 30,
    DEFAULT: 60,
  },
  [BOOL]: {
    MIN: 30,
    DEFAULT: 40,
  },
  OTHER_TYPES: {
    MIN: 100,
    DEFAULT: 100,
  },
  VERTICAL: {
    MIN: 30,
    DEFAULT: 60,
  },
  BOOL_SELECTOR: {
    MIN: 130,
    DEFAULT: 130
  }
};
