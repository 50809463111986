import {IHelpArticleState} from './types';

export enum ActionTypes {
  TOGGLE_HELP_ARTICLE_DRAWER = 'helpArticle/TOGGLE_HELP_ARTICLE_DRAWER',
  CLOSE_ALL_DRAWERS = 'helpArticle/CLOSE_ALL_DRAWERS'
}

export const toggleHelpArticleDrawer = (state: IHelpArticleState) => {
  return {
    type: ActionTypes.TOGGLE_HELP_ARTICLE_DRAWER,
    payload: state
  };
};

export const closeAllHelpDrawers = () => {
  return {
    type: ActionTypes.CLOSE_ALL_DRAWERS,
  };
};
