import {fields} from 'services/objects';
import {
  GroupOrientation,
  HorizontalAlign,
  OrientationStr,
  SplitterHandle,
  TitleType,
  VerticalAlign
} from 'services/interfaces/global-interfaces';
import {auto, colRangeNumber, locateOnGrid} from 'utilsOld/gridUtils';
import {SysFormGroup} from '../../services/interfaces/sysObjects';

export default class Group {
  private readonly group: SysFormGroup;

  constructor(group: SysFormGroup) {
    this.group = group;
  }

  public get id(): number {
    return this.group[fields.ID];
  }

  public get formID(): string {
    return this.group[fields.FormID];
  }

  public get parentGroupID() {
    return this.group[fields.ParentGroupID] ?? null;
  }

  public get fieldTitleDefaultWidth() {
    return this.group[fields.FieldTitleDefaultWidth];
  }

  public get IsGroupBodyScrollable() {
    return this.group[fields.IsGroupBodyScrollable];
  }

  public get title(): string {
    return this.group[fields.Title];
  }

  public get orientation(): GroupOrientation {
    return this.group[fields.Orientation];
  }

  public get orientationStr(): OrientationStr {
    const {ROW, COLUMN} = OrientationStr;
    return this.orientation === GroupOrientation.ROW ? ROW : COLUMN;
  }

  public get isShowTitle(): boolean {
    return !!this.group[fields.IsShowTitle];
  }

  public get groupType(): number {
    return this.group[fields.GroupType];
  }
  public get tabPosition(): number {
    return this.group[fields.TabPosition];
  }

  public get icon(): string {
    return this.group[fields.Icon] ?? '';
  }

  public get titleType(): TitleType {
    return this.group[fields.TitleType];
  }

  public get isStartToolbarSection(): boolean {
    return !!this.group[fields.IsStartToolbarSection];
  }

  public get docUrl(): string {
    return this.group[fields.DocURL] ?? '';
  }

  public get helpArticleGuid(): string {
    return this.group[fields.HelpArticleGuid] ?? '';
  }

  public get hint(): string {
    return this.group[fields.Hint] ?? '';
  }

  public get isCollapsed(): boolean {
    return !!this.group[fields.IsCollapsed];
  }

  public get isCustomizable(): boolean {
    return !!this.group[fields.IsCustomizable];
  }

  public get isActive(): boolean {
    return Boolean(this.group[fields.IsActive]);
  }

  public get order(): number {
    return this.group[fields.Order];
  }

  public get cols(): colRangeNumber | auto | undefined {
    return locateOnGrid(this.group).cols;
  }

  public get offset(): colRangeNumber | undefined {
    return locateOnGrid(this.group).offset;
  }

  public get newLine(): boolean {
    return Boolean(locateOnGrid(this.group).newLine);
  }

  public get rows(): number {
    return 0;
  }

  public get isVisible(): boolean {
    return !!this.group[fields.IsVisible];
  }

  public get width(): number | null {
    return this.group[fields.Width] ?? null;
  }

  public get groupWidth(): string | null {
    return this.group[fields.GroupWidth] ?? null;
  }

  public get style(): string {
    return this.group[fields.Style] ?? '';
  }

  public get name(): string {
    return this.group[fields.Name];
  }

  public get isHideInOtherActions(): boolean {
    return !!this.group[fields.IsHideInOtherActions];
  }

  public get splitter(): SplitterHandle {
    return this.group[fields.Splitter] || undefined;
  }

  public get isResizable(): boolean {
    return !!this.group[fields.IsResizable];
  }

  public get colorScheme() {
    return this.group.ColorScheme;
  }

  public get stylingMode() {
    return this.group.StylingMode;
  }

  get translations() {
    return this.group[fields.Translations];
  }

  public get displayCustomParam() {
    return this.group.DisplayCustomParam;
  }

  public get titleFontStyle() {
    return this.group.TitleFontStyle;
  }

  public get verticalAlign(): VerticalAlign {
    return this.group.VerticalAlignment ?? VerticalAlign.Top;
  }

  public get horizontalAlign(): HorizontalAlign {
    return this.group.HorizontalAlignment ?? HorizontalAlign.Left;
  }
}
