import {IHelpArticleAction, IHelpArticleState} from './types';
import {ActionTypes} from './actions';

const initialState: IHelpArticleState = {
  visibleModal: true,
  visibleDrawer: false,
};

export const helpArticleReducer = (state = initialState, {type, payload}: IHelpArticleAction): IHelpArticleState => {
  switch (type) {
    case ActionTypes.TOGGLE_HELP_ARTICLE_DRAWER:
      return {
        ...state,
        ...payload
      };
    case ActionTypes.CLOSE_ALL_DRAWERS: {
      return initialState;
    }
    default:
      return state;
  }
};
