import {defaultRegionalLocale} from './actions';
import {actionTypes as modalTypes} from '../modal/actions';
import {system} from '../objects';
import {AlternativeItems, LanguageCode, Locale, NEW_WINDOW_OPENING_MODE} from '../interfaces/global-interfaces';
import {
  getAppAltColor,
  getAppHorizSeparLines,
  getAppVertSeparLines,
  getRegionalSettings
} from 'services/SecondaryMethods/userSettings';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TreeItemID} from 'components/NavBar/types';

const {RegionalLocale} = getRegionalSettings();

export const getAdditionalSettings = () => {
  return {
    altColor: getAppAltColor() || AlternativeItems.Default,
    horizSeparLines: getAppHorizSeparLines() || AlternativeItems.Default,
    vertSeparLines: getAppVertSeparLines() || AlternativeItems.Default
  };
};

export type ILanguage = {
  id: number;
  code: LanguageCode;
  title: string;
};

const languageItems: ILanguage[] = [
  {
    id: 2,
    code: LanguageCode.ua,
    title: system.LANGUAGES.UKRAINIAN
  },
  // {
  //   id: 3,
  //   code: LanguageCode.ru,
  //   title: system.LANGUAGES.RUSSIAN
  // },
  {
    id: 1,
    code: LanguageCode.en,
    title: system.LANGUAGES.ENGLISH
  }
];

const initialState: OverlayState = {
  globalLoaderIsVisible: false,
  currentSubsystemID: null,
  currenDir: {
    id: null,
    name: '',
    title: '',
    parentID: null
  },
  currentFormID: null,
  prevFromIDs: [],
  formType: null,
  mainDrawerOpened: false,
  objectName: '',
  viewMode: 2,
  isShowTitle: false,
  regionalSettings: RegionalLocale ? RegionalLocale : defaultRegionalLocale,
  userInteractionsDisabled: false,
  lang: LanguageCode.ua,
  newWindowOpenedMode: NEW_WINDOW_OPENING_MODE.HOME_URL,
  editorStylingMode: '',
  servicePrefix: '',
  languages: languageItems,
  ...getAdditionalSettings()
};

type NavTreeCurrentDir = {
  id: TreeItemID | null;
  parentID: TreeItemID | null;
  name: string;
  title: string;
};

export type OverlayState = {
  globalLoaderIsVisible: boolean;
  currentSubsystemID: null | TreeItemID;
  currenDir: NavTreeCurrentDir;
  currentFormID: null | string;
  prevFromIDs: (string | null)[];
  lang: LanguageCode;
  newWindowOpenedMode: NEW_WINDOW_OPENING_MODE;
  formType: any;
  altColor: AlternativeItems;
  horizSeparLines: AlternativeItems;
  vertSeparLines: AlternativeItems;
  mainDrawerOpened: boolean;
  userInteractionsDisabled: boolean;
  isShowTitle: boolean;
  objectName: string;
  editorStylingMode: string;
  servicePrefix: string;
  viewMode: number;
  regionalSettings: Locale;
  languages: ILanguage[];
};

const {actions, reducer} = createSlice({
  initialState,
  name: 'overlay',
  reducers: {
    setCurrentSubsystemID(state, {payload}: PayloadAction<number>) {
      state.currentSubsystemID = payload;
    },
    setCurrenDir(state, {payload}: PayloadAction<NavTreeCurrentDir | null>) {
      state.currenDir = payload ?? {
        id: null,
        name: '',
        title: '',
        parentID: null
      };
    },
    setCurrentValues(state, {payload}: PayloadAction<string>) {
      state.currentFormID = payload;
    },
    setWindowOpenedMode(state, {payload}: PayloadAction<NEW_WINDOW_OPENING_MODE>) {
      state.newWindowOpenedMode = payload;
    },
    setUserInteractionDisabled(state, {payload}: PayloadAction<boolean>) {
      state.userInteractionsDisabled = payload;
    },
    //set service prefix from url admin/configurator
    setServicePrefix(state, {payload}: PayloadAction<string>) {
      state.servicePrefix = payload;
    },
    showGlobalLoader(state) {
      state.globalLoaderIsVisible = true;
    },
    hideGlobalLoader(state) {
      state.globalLoaderIsVisible = false;
    },
    mainDrawerToggle(state, {payload}: PayloadAction<boolean>) {
      state.mainDrawerOpened = payload;
    },

    resetSettingsState(state) {
      state.regionalSettings = defaultRegionalLocale;
      state.altColor = AlternativeItems.Default;
      state.horizSeparLines = AlternativeItems.Default;
      state.vertSeparLines = AlternativeItems.Default;
    },
    partialUpdateState(state, {payload}: PayloadAction<Partial<OverlayState>>) {
      for (const payloadKey in payload) {
        // @ts-ignore
        state[payloadKey] = payload[payloadKey];
      }
    }
  },
  extraReducers: builder => {
    builder
      // @ts-ignore
      .addCase(modalTypes.OPEN, (state, {payload}: PayloadAction<{actionFormId: string}>) => {
        state.prevFromIDs.push(state.currentFormID);
        state.currentFormID = payload.actionFormId;
      })
      .addCase(modalTypes.CLOSE, state => {
        state.currentFormID = state.prevFromIDs.pop() ?? null;
      });
  }
});
export const {
  partialUpdateState,
  mainDrawerToggle,
  resetSettingsState,
  setServicePrefix,
  showGlobalLoader,
  hideGlobalLoader,
  setCurrentSubsystemID,
  setCurrentValues,
  setUserInteractionDisabled,
  setCurrenDir,
  setWindowOpenedMode
} = actions;

export default reducer;
